import React from 'react';
import { Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Slide } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/20/solid'

import NotFound from './pages/notfound'
import Dashboard from './pages/dashboard'
import Report from './pages/report'
// import Test from './pages/test'
import About from './pages/about'
import MarketPlace from './pages/marketplace'
import Home from './pages/home'
import SignIn from './pages/signin'

const CloseButton = ({ closeToast }) => (
  <XMarkIcon className='w-5 h-5 absolute top-2 right-2 text-zinc-400 dark:text-zinc-200 hover:text-zinc-500' onClick={closeToast} />
);

function App() {
  const token = localStorage.getItem('token');

  return (
    <div id="App" className="App">
      <ToastContainer
        toastClassName={() => "font-sans pointer-events-auto w-full max-w-sm mt-1 rounded-lg bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black dark:ring-zinc-600 ring-opacity-5 flex w-full"}
        closeButton={CloseButton}
        hideProgressBar={true}
        transition={Slide}
        position="top-right"
        autoClose={3000}
        stacked
      />
      
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/dashboard" element={token ? <Dashboard /> : <SignIn />} />
        <Route path="/dashboard/:brand" element={token ? <Dashboard /> : <SignIn />} />
        <Route path="/report/:brand/:id" element={token ? <Report /> : <SignIn />} />
        <Route path="/about" element={<About />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        {/* <Route path="/test" element={<Test />} /> */}
      </Routes>
    </div>
  );
}

export default App;