export default function KPI({ title, value, background, loading, difference }) {
    return (
        <div id='kpi-revenue' className={' h-40 sm:h-full rounded-xl shadow ' + background}>
            <div className='relative p-7 h-full w-full'>
                <div className="absolute left-6 top-4 inline-block">
                    <div className="inline-flex items-center">
                        <span className='text-md sm:text-sm 2xl:text-sm text-white 2xl:font-semibold font-heading'>
                            {title}
                        </span>
                        {loading && (
                            <div className="ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" />
                        )}
                    </div>
                </div>
                <span className='absolute left-6 bottom-5 text-2xl sm:text-2xl 2xl:text-2xl text-white font-heading'>{value}</span>
                {difference
                    ? difference.includes('+')
                        ? <span className="absolute right-5 top-5 inline-flex text-center items-center rounded-md bg-green-200 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">{difference}</span>
                        : difference === '0%'
                            ? <span className="absolute right-5 top-5 inline-flex text-center items-center rounded-md bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10">{difference}</span>
                            : difference.includes('-')
                                ? <span className="absolute right-5 top-5 inline-flex text-center items-center rounded-md bg-red-200 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10">{difference}</span>
                                : null
                    : null
                }
            </div>
        </div >
    )
}