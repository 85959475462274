import React, { Component } from "react";
import Chart from "react-apexcharts";
import colors from "tailwindcss/colors";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAxisLineY: false,
      showAxisLineX: true,
      horizontal: false,
      theme: 'dark',
      chartData: [0],
      chartCategories: [0],
      titleAxisY: ''
    };
  }

  componentDidMount() {
    this.setState({
      showAxisLineY: this.props.showAxisLineY,
      showAxisLineX: this.props.showAxisLineX,
      horizontal: this.props.horizontal,
      theme: this.props.theme,
      chartData: this.props.chartData,
      chartCategories: this.props.chartCategories,
      chartOptions: this.props.chartOptions,
      titleAxisY: this.props.titleAxisY
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme || prevProps.chartData !== this.props.chartData || prevProps.chartCategories !== this.props.chartCategories) {
      this.setState({
        showAxisLineY: this.props.showAxisLineY,
        showAxisLineX: this.props.showAxisLineX,
        horizontal: this.props.horizontal,
        theme: this.props.theme,
        chartData: this.props.chartData,
        chartCategories: this.props.chartCategories,
        titleAxisY: this.props.titleAxisY
      });
    }
  }

  render() {
    const zoomInSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" style="fill: transparent !important;" viewBox="0 0 24 24" stroke-width="1.5" stroke="${this.props.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]}" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" /></svg>`;
    const homeSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" style="fill: transparent !important;" viewBox="0 0 24 24" stroke-width="1.5" stroke="${this.props.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]}" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>`
    const selectionSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" style="fill: transparent !important;" viewBox="0 0 24 24" stroke-width="1.5" stroke="${this.props.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]}" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" /></svg>`
    const plusSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" style="fill: transparent !important;" viewBox="0 0 24 24" stroke-width="1.5" stroke="${this.props.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]}" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>`
    const minSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" style="fill: transparent !important;" viewBox="0 0 24 24" stroke-width="1.5" stroke="${this.props.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]}" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>`

    return (
      <Chart
        options={{
          chart: {
            stacked: true,
            selection: {
              enabled: true
            },
            toolbar: {
              show: true,
              offsetX: 2,
              offsetY: -33,
              tools: {
                download: false,
                selection: selectionSvg,
                zoom: zoomInSVG,
                zoomin: plusSvg,
                zoomout: minSvg,
                pan: false,
                reset: homeSvg,
              },
            }
          },
          tooltip: {
            style: {
              fontSize: "12px",
            },
            onDatasetHover: {
              style: {
                fontSize: "12px",
              },
            },
            theme: this.state.theme,
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName !== 'undefined' ? this.props.titleAxisY + " of " + seriesName : this.props.titleAxisY,
              },
            },
          },
          xaxis: {
            categories: this.state.chartCategories,
            type: 'category',
            show: false,
            tickPlacement: true,
            labels: {
              show: true,
              hideOverlappingLabels: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "9px",
                fontWeight: "500",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            min: 0,
            color: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
            labels: {
              show: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "9px",
                fontWeight: "500",
              },
              formatter: function (val) {
                return parseFloat(val) > 0
                  ? val % 1 === 0
                    ? val
                    : val.toFixed(2)
                  : val;
              }
            },
          },

          grid: {
            borderColor: this.state.theme === 'dark' ? colors.zinc[700] : colors.zinc[300],
            show: true,
            yaxis: {
              lines: {
                show: this.state.showAxisLineY,
                opacity: 0.5,
              },
            },
            row: {
              opacity: 0.5,
            },
            xaxis: {
              lines: {
                show: this.state.showAxisLineX,
                opacity: 0.5,
              },
            },
          },
          colors: this.state.theme === 'dark' ? [colors.zinc[300], colors.sky[300], colors.indigo[300], colors.amber[300], colors.emerald[300], colors.rose[300]] : [colors.zinc[600], colors.sky[600], colors.indigo[600], colors.amber[600], colors.emerald[600], colors.rose[600]],
          fill: {
            type: "solid",
            opacity: 0.9,
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
            fontSize: '9px',
            labels: {
              colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[600]
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: this.state.horizontal,
              borderRadius: this.state.chartCategories !== undefined && this.state.chartCategories.length >= 8 ? 3 : 5,
              columnWidth: "80%",
            },
          },
        }}
        series={this.state.chartData}
        type='bar'
        width='100%'
        height='100%'
      />
    );
  }
}

export default BarChart;
