import { database, containers } from './configs/CosmosConfig';

function getWeekNumber(date) {
    const d = new Date(date);
    d.setHours(0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
}

export const getTimelineData = async (metric, datetime_part, OrganizationUnits, dates, filters) => {
    try {
        const container = database.container(containers.order);

        const dateFrom = dates.startDate;
        const dateTo = dates.endDate;

        var source = 'c'
        var filter = 'c.IsPaid = true'

        if (OrganizationUnits.length > 0) {
            filter += ' AND c.SoldFromOrganizationUnitID IN (' + OrganizationUnits + ')'
        }
        if (dateFrom && dateTo) {
            filter += ' AND DateTimeToTimestamp(c.PaymentDate) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.PaymentDate) < ' + dateTo
        }

        var query_timepart = datetime_part
        if (datetime_part === 'week') {
            query_timepart = 'yyyy-MM-dd'
        }

        var query_datetime = ''
        if (query_timepart.includes('-')) {
            query_datetime = 'LEFT(c.PaymentDate, ' + query_timepart.length + ')'
        }
        else {
            query_datetime = "DateTimePart('" + query_timepart + "', c.PaymentDate)"
        }
        if (query_timepart === 'ou') {
            query_datetime = "c.SoldFromOrganizationUnitData.Name"
        }
        if (query_timepart === 'relative') {
            query_datetime = 'ROUND((DateTimeToTimestamp(c.PaymentDate) - ' + dateTo + ') / 86400000)'
        }

        var query_metric = ''
        var operator = ''
        if (metric === 'Orders') {
            query_metric = 'c.id'
            operator = 'COUNT'
        }
        if (metric === 'Revenue') {
            query_metric = 'c.TotalAmountInTax'
            operator = 'SUM'
        }
        if (metric === 'Consumers') {
            query_metric = 'DISTINCT c.Customer.ID'
            operator = 'COUNT'
        }

        if(filters?.CountryID) {
            filter += " AND  c.ShippingAddress.CountryID = '" + filters.CountryID + "'"
        }

        if(filters?.PaymentMethod) {
            source += " JOIN p in c.Payments"
            filter += " AND  p.Description = '" + filters.PaymentMethod + "'"
        } 

        if (filters?.Product) {
            source += " JOIN l in c.Lines"
            filter += " AND  l.Product.PrimitiveName LIKE '" + filters.Product + "%'"
        }

        if (filters?.Customers) {
            filter += " AND  c.Customer.ID IN (" + filters?.Customers + ")"
        }

        if (filters?.BarDates && filters?.BarDates[0] !== 'ou') {
            if (String(filters?.BarDates[1]).includes('-')) {
                filter += " AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") >= '" + filters.BarDates[1] + "' AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") <= '" + filters.BarDates[2] + "'"
            }
            else {
                filter += " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) >= " + filters.BarDates[1] + " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) <= " + filters.BarDates[2]
            }
        }

        var query = {
            query: `
                SELECT
                    data.timestamp_part
                    ,` + operator + `(data.metric) AS 'value'
                FROM (
                    SELECT
                    ` + query_metric + ` AS metric,
                    ` + query_datetime + ` AS timestamp_part
                    FROM
                        ` + source + `
                    WHERE
                        ` + filter + `
                ) AS data
                GROUP BY data.timestamp_part
            `
        };
        var { resources } = await container.items
            .query(query)
            .fetchAll();

        if (datetime_part === 'week') {
            var groupedData = resources.reduce(function(acc, obj) {
                var weekNumber = getWeekNumber(obj.timestamp_part);
                acc[weekNumber] = acc[weekNumber] || {"timestamp_part": weekNumber.toString(), "value": 0};
                acc[weekNumber].value += obj.value;
                return acc;
            }, {});

            resources = Object.keys(groupedData).map(function(key) {
                return groupedData[key];
            });
        }

        return resources;
    } catch (error) {
        console.error('Error fetching timeline stats:', error);
        throw error;
    }
};