import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Draggable from 'react-draggable';
import { ChartBarIcon, ChatBubbleOvalLeftEllipsisIcon, Cog8ToothIcon, PlusCircleIcon, PlusIcon, PresentationChartLineIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Notification from '../components/notification';
import Loading from './loading';
import { url } from '../api/configs/EvaConfig';
import EvaDarkLogo from '../assets/eva_dark.png';
import KikoLogo from '../assets/kiko.png';
import AjaxLogo from '../assets/ajax.png';
import Chat from '../components/chat/chat';
import { getDashboard, patchChartValue, patchDashboardLayout, queryData } from '../api/dashboards';
import NotFound from './notfound';
import BarChart from '../components/charts/BarChart'; // Import your BarChart component
import AreaChart from '../components/chat/charts/AreaChart';
import KPI from '../components/charts/KPI';
import { Button } from '../components/button';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/dropdown';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog'
import { Field, Label } from '../components/fieldset'
import { Input } from '../components/input'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert'
import GridLayout from "react-grid-layout";
import { MoonIcon, SunIcon } from '@heroicons/react/20/solid';

const logos = {
    default: EvaDarkLogo,
    guc: EvaDarkLogo,
    kiko: KikoLogo,
    ajax: AjaxLogo,
};

const checkAccessRights = (email, data) => {
    // Check if email is the user property
    if (email === data.user) {
        return 'admin';
    }
    // Check if email has admin or edit rights
    if (data.access && data.access[email]) {
        const rights = data.access[email].rights;
        if (rights.includes('admin')) {
            return 'admin';
        }
        if (rights.includes('edit')) {
            return 'edit';
        }
    }
    return 'view';
};


export default function Report() {
    const [theme, setTheme] = useState('dark');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [signedIn, setSignedIn] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' });
    const [showChat, setShowChat] = useState(false);
    const [chat, setChat] = useState(null);
    const [resetChat, setResetChat] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [chartsData, setChartsData] = useState({});
    const [selectedChart, setSelectedChart] = useState({});
    const [changeChart, setChangeChart] = useState(false);
    const [changeKey, setChangeKey] = useState(null);
    const [deleteChart, setDeleteChart] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null);

    const { brand, id } = useParams();

    useEffect(() => {
        if (!token) {
            localStorage.removeItem('token');
            window.location.reload();
        } else {
            const fetchData = async () => {
                try {
                    const api_url = url[brand] ? url[brand] : url['default'];
                    const response = await fetch('https://' + api_url + '/message/Ping', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                    });
                    if (response.ok) {
                        const user_response = await fetch('https://' + api_url + '/api/core/GetCurrentUser', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': token,
                            },
                        });
                        if (user_response.ok) {
                            const userData = await user_response.json();
                            setUser(userData?.User);
                            if (userData?.User?.FirstName) {
                                Notification('info', 'Welcome back ' + userData.User.FirstName + '!', 'Check now the performance of today.');
                            } else {
                                Notification('info', 'Welcome back!', 'Check now the performance of today.');
                            }
                            setSignedIn(true);
                        }
                    } else {
                        localStorage.removeItem('token');
                        window.location.reload();
                        Notification('error', 'Login expired', 'Please sign in again to access the dashboard.');
                        setSignedIn(false);
                    }
                } catch (error) {
                    localStorage.removeItem('token');
                    window.location.reload();
                    Notification('error', 'Something went wrong!', 'Please sign in again or contact support.');
                    setSignedIn(false);
                }
            };
            fetchData();
        }
    }, [brand, token]);

    useEffect(() => {
        if (user && dashboard) {
            setAccessLevel(
                checkAccessRights(user?.EmailAddress, dashboard)
            );
        }
    }, [user, dashboard]);

    const applyFilters = (filters) => { };

    useEffect(() => {
        const fetchDashboard = async () => {
            try {
                var dashboardData = await getDashboard(id, user?.EmailAddress);
                setDashboard(dashboardData);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) {
            fetchDashboard();
        }
    }, [signedIn, id, user, refresh]);

    function renderChart(chart) {
        var chartData = [];
        var chartCategories = [];
        var groupedData = [];
        var metric = '';

        switch (true) {
            case chart?.type?.includes('metric'):
                return <KPI title={chart?.title} value={chart?.data[0][chart?.x]} background={'bg-gradient-to-br from-zinc-500 dark:from-zinc-700 from-10% to-zinc-400 dark:to-zinc-900 to-80%'} />;
            case chart?.type?.includes('bar'):
                metric = chart?.y;
                groupedData = chart.data.reduce((acc, curr) => {
                    const groupName = curr[chart.hue];
                    if (!acc[groupName]) {
                        acc[groupName] = [];
                    }
                    acc[groupName].push(curr[chart?.y]);
                    return acc;
                }, {});
                chartData = Object.entries(groupedData).map(([name, data]) => ({
                    name,
                    data
                }));
                chartCategories = Array.from(new Set(chart?.data.map(item => item[chart?.x])));

                return <div className="w-full h-full rounded-xl shadow dark:shadow-zinc-700 bg-white dark:bg-zinc-950 ring-1 ring-zinc-400 dark:ring-zinc-800 ring-opacity-5">
                    <div className='flex h-12 sm:h-12 2xl:h-12 rounded-t-lg bg-zinc-50 dark:bg-zinc-900 border-b border-zinc-200 dark:border-zinc-700'>
                        <ChartBarIcon className='text-zinc-800 dark:text-zinc-200 ml-4 my-auto w-4 align' />
                        <span className='my-auto text-center ml-3 font-light text-xs text-zinc-800 dark:text-zinc-200 hidden sm:block overflow-x-hidden'>{chart?.title}</span>
                    </div>
                    <div className='px-3 w-full h-[calc(100%-61px)] items-center align-middle relative'>
                        <BarChart showAxisLineY={true} showAxisLineX={false} theme={theme} horizontal={false} chartData={chartData} chartCategories={chartCategories} titleAxisY={metric} />
                    </div>
                </div>;
            case chart?.type?.includes('line'):
                metric = chart?.y;
                groupedData = chart.data.reduce((acc, curr) => {
                    const groupName = curr[chart.hue];
                    if (!acc[groupName]) {
                        acc[groupName] = [];
                    }
                    acc[groupName].push(curr[chart?.y]);
                    return acc;
                }, {});
                chartData = Object.entries(groupedData).map(([name, data]) => ({
                    name,
                    data
                }));
                chartCategories = Array.from(new Set(chart?.data.map(item => item[chart?.x])));

                return <div className="w-full rounded-xl shadow dark:shadow-zinc-700 bg-white dark:bg-zinc-950 ring-1 ring-zinc-400 dark:ring-zinc-800 ring-opacity-5">
                    <div className='flex h-12 sm:h-12 2xl:h-12 rounded-t-lg bg-zinc-50 dark:bg-zinc-900 border-b border-zinc-200 dark:border-zinc-700'>
                        <PresentationChartLineIcon className='text-zinc-800 dark:text-zinc-200 ml-4 my-auto w-4 align' />
                        <span className='my-auto text-center ml-3 font-light text-xs text-zinc-800 dark:text-zinc-200 hidden sm:block overflow-x-hidden'>{chart?.title}</span>
                    </div>
                    <div className='px-3 w-full h-[calc(100%-61px)] items-center align-middle relative'>
                        <AreaChart showAxisLineY={true} showAxisLineX={false} theme={theme} horizontal={false} chartData={chartData} chartCategories={chartCategories} titleAxisY={metric} />
                    </div>
                </div>;
            default:
                return null;
        }
    }

    useEffect(() => {
        const fetchChartData = async () => {
            if (dashboard?.dashboard) {
                const dataPromises = Object.keys(dashboard.dashboard).map(async (key) => {
                    const chart = dashboard.dashboard[key];
                    const data = await queryData(chart?.query);
                    return { key, data };
                });

                const data = await Promise.all(dataPromises);
                const newChartsData = {};
                data.forEach(({ key, data }) => {
                    newChartsData[key] = data;
                });
                setChartsData(newChartsData);
            }
        };

        if (dashboard) {
            fetchChartData();
        }
    }, [dashboard, refresh]);

    const handleChartUpdate = async (operation, id, creator, chart, key, value) => {
        try {
            await patchChartValue(operation, id, creator, chart, key, value)
            Notification('success', 'Chart updated.', 'Your chart is successfuly modified.')
            setRefresh(crypto.randomUUID());
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when updating your chart, please try again or contact support.')
            console.log(error);
        }
    };

    const handleLayoutChange = async (newLayout) => {
        const transformedLayout = {};
        newLayout.forEach(item => {
            const { i, w, h, x, y } = item;
            transformedLayout[i] = { w, h, x, y };
        });
        try {
            await patchDashboardLayout(dashboard.id, dashboard.user, transformedLayout)
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when updating your dashbaord layout, please try again or contact support.')
            console.log(error);
        }
    };

    function switchTheme() {
        setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
        document.body.classList.toggle("dark");
    }

    return (
        <>
            {signedIn ? (
                dashboard?.dashboard ? (
                    <div className='flex xl:h-screen w-screen bg-gradient-to-br from-zinc-100 dark:from-zinc-800 from-5% to-zinc-300 dark:to-black to-60%'>
                        <main className='flex flex-col mx-4 mb-4 lg:mx-6 lg:mb-6 w-screen'>
                            <div id='header' className='flex flex-col lg:flex-row h-fit lg:h-24 py-5 lg:py-0 w-full items-start lg:items-center justify-between'>
                                <a href='/dashboard'>
                                    <img
                                        src={logos[brand] ? logos[brand] : logos['default']}
                                        alt='EVA'
                                        className={`${['ajax'].includes(brand) ? 'h-12' : 'h-7'} w-auto mb-6 lg:mb-0 ${theme === 'dark' ? 'invert' : ''}`}
                                    />
                                </a>
                                <div className='flex flex-col lg:flex-row gap-x-2 gap-y-1 lg:gapy-0 items-center w-full lg:w-fit'>
                                    <div className='w-full lg:w-fit'>
                                        <Button plain onClick={() => switchTheme()} className="mr-2">
                                            {theme === 'dark'
                                                ? <MoonIcon />
                                                : <SunIcon />
                                            }
                                        </Button>
                                        <Button color={theme === 'dark' ? 'dark' : 'white'} onClick={() => setShowChat(true)}>
                                            <PresentationChartLineIcon />
                                            Add chart
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div id='dashboard' className='w-full h-full'>

                                <Dialog open={changeChart} onClose={() => setChangeChart(false)}>
                                    <DialogTitle>Change {changeKey}</DialogTitle>
                                    <DialogDescription>
                                        Change the {changeKey} of chart '{selectedChart?.title}'.
                                    </DialogDescription>
                                    <DialogBody>
                                        <Field>
                                            <Label>{changeKey?.charAt(0)?.toUpperCase() + changeKey?.slice(1)}</Label>
                                            <Input id="chart-value" name="value" defaultValue={selectedChart[changeKey]} autoFocus />
                                        </Field>
                                    </DialogBody>
                                    <DialogActions>
                                        <Button plain onClick={() => { setChangeChart(false); }}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => { handleChartUpdate('replace', dashboard.id, dashboard.user, selectedChart.id, changeKey, document.getElementById('chart-value')?.value); setChangeChart(false); }}>Save</Button>
                                    </DialogActions>
                                </Dialog>

                                <Alert open={deleteChart} onClose={() => setDeleteChart(false)}>
                                    <AlertTitle>Are you sure you want to delete this chart?</AlertTitle>
                                    <AlertDescription>
                                        This will delete the chart '{selectedChart?.title}'
                                    </AlertDescription>
                                    <AlertActions>
                                        <Button plain onClick={() => { setDeleteChart(false); }}>
                                            Cancel
                                        </Button>
                                        <Button color="red" onClick={() => { handleChartUpdate('remove', dashboard.id, dashboard.user, selectedChart.id, null, null); setDeleteChart(false) }}>Delete</Button>
                                    </AlertActions>
                                </Alert>


                                {isSmallScreen ? (
                                    <div className='grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-5 sm:grid-rows-3 w-full xl:h-full gap-4'>
                                        {
                                            Object.keys(dashboard?.dashboard)?.map((key, index) => {
                                                return chartsData[key] ? (
                                                    <div key={index} className="relative h-96 xl:h-full">
                                                        {renderChart({ ...dashboard.dashboard[key], data: chartsData[key] })}
                                                        {accessLevel === 'admin' || accessLevel === 'edit'
                                                            ? <div className="absolute top-1.5 right-1.5 z-10">
                                                                <Dropdown>
                                                                    <DropdownButton plain aria-label="More options">
                                                                        <Cog8ToothIcon />
                                                                    </DropdownButton>
                                                                    <DropdownMenu anchor="bottom end">
                                                                        <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('title'); setChangeChart(true) }}>Edit title</DropdownItem>
                                                                        <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('x'); setChangeChart(true) }}>Edit x axis</DropdownItem>
                                                                        <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('y'); setChangeChart(true) }}>Edit y axis</DropdownItem>
                                                                        <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('query'); setChangeChart(true) }}>Edit query</DropdownItem>
                                                                        <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setDeleteChart(true) }}>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                            : null}
                                                    </div>
                                                ) : null;
                                            })
                                        }
                                    </div>
                                ) : (
                                    <GridLayout
                                        className="layout"
                                        cols={5}
                                        rowHeight={window.innerHeight / 3 - 60}
                                        width={window.innerWidth}
                                        maxRows={3}
                                        onLayoutChange={handleLayoutChange}
                                        draggableCancel=".cancelSelectorName"
                                    >
                                        {Object.keys(dashboard?.dashboard)?.map((key, index) => {
                                            return chartsData[key] ? (
                                                <div key={key} data-grid={dashboard?.layout ? dashboard.layout[key] : null} className="relative h-96 xl:h-full group">
                                                    {renderChart({ ...dashboard.dashboard[key], data: chartsData[key] })}
                                                    {accessLevel === 'admin' || accessLevel === 'edit'
                                                        ? <div className="absolute top-1.5 right-1.5 z-auto">
                                                            <Dropdown>
                                                                <DropdownButton className="cancelSelectorName" plain aria-label="More options">
                                                                    <Cog8ToothIcon />
                                                                </DropdownButton>
                                                                <DropdownMenu anchor="bottom end">
                                                                    <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('title'); setChangeChart(true) }}>Edit title</DropdownItem>
                                                                    <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('x'); setChangeChart(true) }}>Edit x axis</DropdownItem>
                                                                    <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('y'); setChangeChart(true) }}>Edit y axis</DropdownItem>
                                                                    <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setChangeKey('query'); setChangeChart(true) }}>Edit query</DropdownItem>
                                                                    <DropdownItem className="text-sm" onClick={() => { setSelectedChart(dashboard.dashboard[key]); setDeleteChart(true) }}>Delete</DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>
                                                        : null}
                                                </div>
                                            ) : null;
                                        })}
                                    </GridLayout>)}
                            </div>
                        </main >
                        {
                            isSmallScreen ? (
                                <div className={`fixed w-full xl:w-[30%] h-full xl:h-[75%] bottom-0 right-0 xl:bottom-4 xl:right-4 z-10 shadow-xl ring-1 ring-zinc-400 ring-opacity-20 xl:rounded-xl ${showChat ? '' : 'hidden'}`} >
                                    <div className='hidden xl:flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                        <ChatBubbleOvalLeftEllipsisIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                        <span className='resize my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>AI Chat</span>
                                        <XMarkIcon onClick={() => setShowChat(showChat ? false : true)} className='text-zinc-800 dark:text-white mr-4 my-auto w-4 align ml-auto' />
                                    </div>
                                    <div className='w-full h-full xl:h-[calc(100%-44px)] items-center align-middle relative'>
                                        <Chat
                                            user={user?.EmailAddress}
                                            id={chat}
                                            theme={theme}
                                            config={{ endpoint: url[brand] ? url[brand] : url['default'], token: token }}
                                            reset={resetChat}
                                            handleFilters={applyFilters}
                                        />
                                    </div>
                                </div >
                            ) : (
                                <Draggable className={`${showChat ? '' : 'hidden'}`}>
                                    <div className={`fixed w-full xl:w-[30%] h-full xl:h-[75%] bottom-0 right-0 xl:bottom-4 xl:right-4 z-10 shadow-xl ring-1 ring-zinc-400 ring-opacity-20 xl:rounded-xl ${showChat ? '' : 'hidden'}`}>
                                        <div className='hidden xl:flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                            <ChatBubbleOvalLeftEllipsisIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                            <span className='resize my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>AI Chat</span>
                                            <XMarkIcon onClick={() => setShowChat(showChat ? false : true)} className='text-zinc-800 dark:text-white mr-4 my-auto w-4 align ml-auto' />
                                        </div>
                                        <div className='w-full h-full xl:h-[calc(100%-44px)] items-center align-middle relative'>
                                            <Chat
                                                user={user?.EmailAddress}
                                                id={chat}
                                                theme={theme}
                                                config={{ endpoint: url[brand] ? url[brand] : url['default'], token: token }}
                                                reset={resetChat}
                                                handleFilters={applyFilters}
                                            />
                                        </div>
                                    </div>
                                </Draggable>
                            )
                        }
                    </div >
                ) : (
                    <NotFound theme={theme} />
                )
            ) : (
                <Loading theme={theme} />
            )}
        </>
    );
}
