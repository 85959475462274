import React, { Component } from "react";
import Chart from "react-apexcharts";
import colors from "tailwindcss/colors";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAxisLineY: false,
      showAxisLineX: true,
      horizontal: false,
      theme: 'dark',
      chartData: [0],
      chartCategories: [0]
    };
  }

  componentDidMount() {
    this.setState({
      showAxisLineY: this.props.showAxisLineY,
      showAxisLineX: this.props.showAxisLineX,
      horizontal: this.props.horizontal,
      theme: this.props.theme,
      chartData: this.props.chartData,
      chartCategories: this.props.chartCategories,
      chartOptions: this.props.chartOptions
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme || prevProps.chartData !== this.props.chartData || prevProps.chartCategories !== this.props.chartCategories) {
      this.setState({
        showAxisLineY: this.props.showAxisLineY,
        showAxisLineX: this.props.showAxisLineX,
        horizontal: this.props.horizontal,
        theme: this.props.theme,
        chartData: this.props.chartData,
        chartCategories: this.props.chartCategories
      });
    }
  }

  render() {
    return (
      <Chart
        options={{
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (event, chartContext, config) => {
                this.props.selectedType
                  ? this.props.setValueFunction([
                    this.props.selectedType,
                    this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null,
                    this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null
                  ])
                  : this.props.setValueFunction(this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null);
              }
            }
          },
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: 'SK-Modernist',
            },
            onDatasetHover: {
              style: {
                fontSize: "12px",
                fontFamily: 'SK-Modernist',
              },
            },
            theme: this.state.theme,
          },
          xaxis: {
            categories: this.state.chartCategories,
            show: false,
            labels: {
              show: true,
              rotateAlways: true,
              hideOverlappingLabels: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "11px",
                fontWeight: "500",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            min: 0,
            color: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
            labels: {
              show: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "11px",
                fontWeight: "500",
              },
              formatter: function (val) {
                return parseFloat(val) > 0
                  ? val % 1 === 0
                    ? val
                    : val.toFixed(2)
                  : val;
              }
            },
          },

          grid: {
            borderColor: this.state.theme === 'dark' ? colors.zinc[700] : colors.zinc[300],
            show: true,
            yaxis: {
              lines: {
                show: this.state.showAxisLineY,
                opacity: 0.5,
              },
              labels: {
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: true
              }
            },
            row: {
              opacity: 0.5,
            },
            xaxis: {
              lines: {
                show: this.state.showAxisLineX,
                opacity: 0.5,
              },
              labels: {
                trim: false,
                rotate: 0,
                minHeight: 40,
                hideOverlappingLabels: true
              }
            },
          },
          fill: {
            type: "solid",
            opacity: 0.9,
            colors: this.state.theme === 'dark' ? [colors.zinc[300]] : [colors.zinc[500]],
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: this.state.horizontal,
              borderRadius: this.state.chartCategories !== undefined && this.state.chartCategories.length >= 8 ? 3 : 5,
              columnWidth: "80%",
            },
          },
        }}
        series={this.state.chartData}
        type='bar'
        width='100%'
        height='100%'
      />
    );
  }
}

export default BarChart;
