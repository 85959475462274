import { database, containers } from './configs/CosmosConfig';

export const getKpis = async (OrganizationUnits, dates, filters) => {
    try {
        var source = 'c'
        var filter = 'c.IsPaid = true'

        if (OrganizationUnits.length > 0) {
            filter += ' AND c.SoldFromOrganizationUnitID IN (' + OrganizationUnits + ')'
        }
        
        const dateFrom = dates.startDate
        const dateTo = dates.endDate
        if (dateFrom && dateTo) {
            filter += ' AND DateTimeToTimestamp(c.PaymentDate) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.PaymentDate) < ' + dateTo
        }

        if(filters?.CountryID) {
            filter += " AND  c.ShippingAddress.CountryID = '" + filters.CountryID + "'"
        }

        if(filters?.PaymentMethod) {
            source += " JOIN p in c.Payments"
            filter += " AND  p.Description = '" + filters.PaymentMethod + "'"
        } 

        if (filters?.Product) {
            source += " JOIN l in c.Lines"
            filter += " AND  l.Product.PrimitiveName LIKE '" + filters.Product + "%'"
        }

        if (filters?.Customers) {
            filter += " AND  c.Customer.ID IN (" + filters?.Customers + ")"
        }

        if (filters?.BarDates && filters?.BarDates[0] === 'ou') {
            filter += " AND c.SoldFromOrganizationUnitData.Name = '" + filters?.BarDates[1] + "'"
        }
        if (filters?.BarDates && filters?.BarDates[0] !== 'ou') {
            if (String(filters?.BarDates[1]).includes('-')) {
                filter += " AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") >= '" + filters.BarDates[1] + "' AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") <= '" + filters.BarDates[2] + "'"
            }
            else {
                filter += " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) >= " + filters.BarDates[1] + " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) <= " + filters.BarDates[2]
            }
        }

        const container = database.container(containers.order);
        var query = {
            query: `
            SELECT 
                SUM(c.TotalAmountInTax) AS NetRevenue
                ,SUM(c.TotalAmount) AS Revenue
                ,COUNT(c.ID) AS Orders
                ,AVG(c.TotalAmountInTax) AS AvgOrderAmount
            FROM 
                ` + source + `   
            WHERE
                ` + filter + `   
            `
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();
        return resources[0];
    } catch (error) {
        console.error('Error fetching kpis:', error);
        throw error;
    }
};



