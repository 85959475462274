import React, { useEffect, useState } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';

const suggestions = [
    'What are the most used payment methods in 2024?',
    'What was the revenue in April 2024?',
    'Refresh the dashboard',
    'Can you filter on current month and show the data of the Netherlands',
    'Filter the data of consumer x',
    'Can you filter on last week and the x collection'
];

export default function ChatOverlay({ setSuggestionChatText }) {
    const [shuffledSuggestions, setShuffledSuggestions] = useState([]);

    useEffect(() => {
        const shuffleSuggestions = () => {
            const shuffled = suggestions.sort(() => Math.random() - 0.5);
            setShuffledSuggestions(shuffled);
        };

        shuffleSuggestions();
    }, []);

    const handleSuggestionChange = (event) => {
        setSuggestionChatText(event.target.value);
    };

    return (
        <div className="flex-1 overflow-hidden">
            <div className="overflow-y-auto flex items-center justify-center h-full m-auto text-zinc-800 dark:text-zinc-200">
                <div className="h-full md:h-fit">
                    <div className="flex flex-col items-center justify-center flex-grow p-4">
                        <div className='flex mb-4 md:mb-8'>
                            <h1 className="text-2xl sm:text-4xl font-base font-heading text-center">EVA Data AI</h1>
                            <SparklesIcon className='ml-2 w-6 md:w-8 animate-pulse' />
                        </div>
                        <div className='flex mb-6 md:mb-8 text-center'>
                            <span className="text-neutral-1  2xl:text-sm font-heading text-md font-light">Ask any data related question on the topics of order data.</span>
                        </div>
                        <span className="text-neutral-1 2xl:text-xs text-base font-base mb-4">Example questions</span>
                        <div className="columns-1 md:columns-2 lg:columns-2 max-w-2xl gap-4">
                            {shuffledSuggestions.slice(0, window.innerWidth <= 640 ? 3 : shuffledSuggestions.length).map((text, index) => (
                                <div key={index} className="mb-4 w-full">
                                    <button type="button" value={text} onClick={handleSuggestionChange} className="outline-none p-3 sm:p-4 bg-white dark:bg-zinc-800 rounded-md border text-sm sm:text-xs border-solid border-neutral-4 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-900 hover:border-zinc-400 w-full">
                                        {text} →
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
