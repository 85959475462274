import { database, containers } from './configs/CosmosConfig';

export const getPayments = async (OrganizationUnits, dates, filters) => {
    try {
        const container = database.container(containers.order);

        const dateFrom = dates.startDate;
        const dateTo = dates.endDate;

        var source = 'c JOIN p in c.Payments'
        var filter = 'c.IsPaid = true'

        if (OrganizationUnits.length > 0) {
            filter += ' AND c.SoldFromOrganizationUnitID IN (' + OrganizationUnits + ')'
        }
        if (dateFrom && dateTo) {
            filter += ' AND DateTimeToTimestamp(c.PaymentDate) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.PaymentDate) < ' + dateTo
        }

        if(filters?.CountryID) {
            filter += " AND  c.ShippingAddress.CountryID = '" + filters.CountryID + "'"
        }

        if (filters?.Product) {
            source += " JOIN l in c.Lines"
            filter += " AND  l.Product.PrimitiveName LIKE '" + filters.Product + "%'"
        }

        if (filters?.Customers) {
            filter += " AND  c.Customer.ID IN (" + filters?.Customers + ")"
        }

        if (filters?.BarDates && filters?.BarDates[0] === 'ou') {
            filter += " AND c.SoldFromOrganizationUnitData.Name = '" + filters?.BarDates[1] + "'"
        }
        if (filters?.BarDates && filters?.BarDates[0] !== 'ou') {
            if (String(filters?.BarDates[1]).includes('-')) {
                filter += " AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") >= '" + filters.BarDates[1] + "' AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") <= '" + filters.BarDates[2] + "'"
            }
            else {
                filter += " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) >= " + filters.BarDates[1] + " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) <= " + filters.BarDates[2]
            }
        }

        var query = {
            query: `
                SELECT
                    p.Description AS Method
                    ,SUM(1) AS Payments
                FROM
                    ` + source + `  
                WHERE
                    ` + filter + `  
                GROUP BY
                    p.Description
            `
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();

        return resources;
    } catch (error) {
        console.error('Error fetching payments:', error);
        throw error;
    }
};