import React, { Component } from "react";
import Chart from "react-apexcharts";
import colors from "tailwindcss/colors";

class CircleChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 'dark',
      chartData: [0],
      chartLabels: ['']
    };
  }

  componentDidMount() {
    this.setState({
      theme: this.props.theme,
      chartData: this.props.chartData,
      chartLabels: this.props.chartLabels,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme || prevProps.chartData !== this.props.chartData) {
      this.setState({
        theme: this.props.theme,
        chartData: this.props.chartData,
        chartLabels: this.props.chartLabels,
      });
    }
  }

  render() {
    return (
      <Chart
        options={{
          chart: {
            type: 'donut',
            width: '30',
            events: {
              dataPointSelection: (event, chartContext, config) => {this.props.setValueFunction(this.state.chartLabels[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartLabels[config.dataPointIndex] : null);}
            }
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          colors: [this.state.theme === 'dark' ? colors.zinc[900] : colors.zinc[900]],
          plotOptions: {
            pie: {
              offsetY: 30,
              customScale: 0.85,
              expandOnClick: false,
              donut: {
                size: "80%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: this.state.theme === 'dark' ? colors.zinc[200] : colors.zinc[900],
                    fontSize: "14px",
                    fontFamily: 'SK-Modernist',
                  },
                  value: {
                    show: true,
                    color: this.state.theme === 'dark' ? colors.zinc[200] : colors.zinc[900],
                    fontSize: "16px",
                    fontFamily: 'SK-Modernist',
                    formatter: function (val) {
                      return parseInt(val);
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    color: this.state.theme === 'dark' ? colors.zinc[200] : colors.zinc[900],
                    fontSize: "14px",
                    fontFamily: 'SK-Modernist',
                    label: "Total",
                  }
                }
              },
            }
          },
          fill: {
            opacity: 1,
            colors: this.state.theme === 'dark' ? [colors[this.props.color][500], colors.zinc[300]] : [colors[this.props.color][500], colors.zinc[500]],
          },
          labels: this.state.chartLabels,
          dataLabels: {
            enabled: false,
            style: {
              color: this.state.theme === 'dark' ? colors.zinc[200] : colors.zinc[900],
              fontSize: "14px",
              fontFamily: 'SK-Modernist',
              fontWeight: 'thin',
            },
            formatter: function (val) {
              return Math.round(val) + "%"
            }
          },
          legend: {
            show: true,
            fontSize: "11px",
            fontFamily: 'SK-Modernist',
            floating: true,
            position: 'top',
            horizontalAlign: 'left',
            labels: {
              colors: this.state.theme === 'dark' ? [colors.zinc[300], colors.zinc[300]] : [colors.zinc[800], colors.zinc[800]]
            },
            markers: {
              width: 9,
              height: 9,
              fillColors: this.state.theme === 'dark' ? [colors[this.props.color][500], colors.zinc[300]] : [colors[this.props.color][500], colors.zinc[500]]
            },
          }
        }
        }
        series={this.props.chartData}
        type='donut'
        width='100%'
        height='100%'
      />
    );
  }
}

export default CircleChart;
