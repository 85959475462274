import { Button } from '../components/button'

export default function NotFound() {
    return (
        <>
            <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 bg-gradient-to-b from-gray-700 from-10% to-black to-60%">
                <div className="text-center">
                    <p className="text-base font-semibold text-gray-300">404</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-100 sm:text-5xl">Page not found</h1>
                    <p className="mt-6 text-base leading-7 text-gray-300">Sorry, we couldn’t find the page you’re looking for.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button href="/home" color="white">Go back home</Button>
                        <a href="mailto:info@in2intel.com" className="text-sm font-semibold text-gray-100">
                            Contact support <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
        </>
    )
}
