import { database, containers } from './configs/CosmosConfig';

export const getCountryData = async (metric, OrganizationUnits, dates, filters) => {
    try {
        const container = database.container(containers.order);

        const dateFrom = dates.startDate;
        const dateTo = dates.endDate;

        var source = 'c'
        var filter = 'c.IsPaid = true AND IS_DEFINED(c.ShippingAddress.CountryID)'

        if (OrganizationUnits.length > 0) {
            filter += ' AND c.SoldFromOrganizationUnitID IN (' + OrganizationUnits + ')'
        }
        if (dateFrom && dateTo) {
            filter += ' AND DateTimeToTimestamp(c.PaymentDate) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.PaymentDate) < ' + dateTo
        }

        var query_metric = ''
        var operator = ''
        if (metric === 'Orders') {
            query_metric = 'c.id'
            operator = 'COUNT'
        }
        if (metric === 'Revenue') {
            query_metric = 'c.TotalAmountInTax'
            operator = 'SUM'
        }
        if (metric === 'Consumers') {
            query_metric = 'c.Customer.ID'
            operator = 'COUNT'
        }

        if (filters?.PaymentMethod) {
            source += " JOIN p in c.Payments"
            filter += " AND  p.Description = '" + filters.PaymentMethod + "'"
        }

        if (filters?.Product) {
            source += " JOIN l in c.Lines"
            filter += " AND  l.Product.PrimitiveName LIKE '" + filters.Product + "%'"
        }

        if (filters?.Customers) {
            filter += " AND  c.Customer.ID IN (" + filters?.Customers + ")"
        }

        if (filters?.BarDates && filters?.BarDates[0] === 'ou') {
            filter += " AND c.SoldFromOrganizationUnitData.Name = '" + filters?.BarDates[1] + "'"
        }
        if (filters?.BarDates && filters?.BarDates[0] !== 'ou') {
            if (String(filters?.BarDates[1]).includes('-')) {
                filter += " AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") >= '" + filters.BarDates[1] + "' AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") <= '" + filters.BarDates[2] + "'"
            }
            else {
                filter += " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) >= " + filters.BarDates[1] + " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) <= " + filters.BarDates[2]
            }
        }

        var query = {
            query: `
                SELECT
                    c.ShippingAddress.CountryID AS country,
                    ` + operator + `(` + query_metric + `) AS result
                FROM
                    ` + source + `  
                WHERE
                    ` + filter + `
                GROUP BY 
                    c.ShippingAddress.CountryID
            `
        };

        var { resources } = await container.items
            .query(query)
            .fetchAll();

        return resources;
    } catch (error) {
        console.error('Error fetching country stats:', error);
        throw error;
    }
};