import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CreditCardIcon, TagIcon, UserIcon, BanknotesIcon, ShoppingBagIcon, ChevronDownIcon, FunnelIcon, Bars3Icon, MagnifyingGlassIcon, ChatBubbleOvalLeftEllipsisIcon, XMarkIcon, ChevronRightIcon, ChevronLeftIcon, BuildingOffice2Icon, ClockIcon, ChevronUpDownIcon, PresentationChartBarIcon, PresentationChartLineIcon, EllipsisHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
    SunIcon as SunIconSolid,
    MoonIcon as MoonIconSolid,
    SparklesIcon as SparklesIconSolid,
    AdjustmentsHorizontalIcon as AdjustmentsHorizontalIconSolid,
    ArrowPathIcon as ArrowPathIconSolid,
    UserIcon as UserIconSolid,
    ArrowRightStartOnRectangleIcon as ArrowRightStartOnRectangleIconSolid,
    ChartBarSquareIcon as ChartBarSquareIconSolid,
    XCircleIcon,
    ChatBubbleOvalLeftEllipsisIcon as ChatBubbleOvalLeftEllipsisIconSolid,
    PlusCircleIcon
} from '@heroicons/react/16/solid'
import { useState } from 'react'
import Datepicker from "react-tailwindcss-datepicker";
import clsx from 'clsx'
import { Tooltip } from 'react-tooltip'
import Notification from '../components/notification'
import Draggable from 'react-draggable';
import { useMediaQuery } from 'react-responsive';
import queryString from 'query-string';

import Loading from './loading';
import { url } from '../api/configs/EvaConfig';
import EvaDarkLogo from '../assets/eva_dark.png'
import KikoLogo from '../assets/kiko.png'
import AjaxLogo from '../assets/ajax.png'
import Tabs from '../components/tabs';
import BarChart from '../components/charts/BarChart';
import ComboChart from '../components/charts/ComboChart';
import AreaChart from '../components/charts/AreaChart';
import CircleChart from '../components/charts/CircleChart';
import LandMap from '../components/charts/LandMap';
import KPI from '../components/charts/KPI';
import { Button } from '../components/button';
import Chat from '../components/chat/chat';
import Search from '../components/search';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
    DropdownSeparator,
    DropdownLabel,
    DropdownShortcut
} from '../components/dropdown'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog'
import { Field, Label } from '../components/fieldset'
import { Input } from '../components/input'
import { Select } from '../components/select'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert'

//import { getOrganizationUnits } from '../api/filters';
import { getKpis } from '../api/kpis';
import { getPayments } from '../api/payments';
import { getCategories } from '../api/categories';
import { getCustomers } from '../api/customers';
import { getTimelineData } from '../api/timeline';
import { getCountryData } from '../api/countries';
import { getDashboards, patchDeleteDashboard, patchDashboardAccessRights, getDashboard, changeDashboardTitle, createDashboard } from '../api/dashboards';

const tabs = [
    { name: 'Orders' },
    { name: 'Revenue' },
    { name: 'Consumers' },
]
const defaultTab = 0

const refreshIntervals = {
    'off': 'Off',
    '5': '5 seconds',
    '30': '30 seconds',
    '60': '1 minute',
    '300': '5 minutes',
    '900': '15 minutes',
    '1800': '30 minutes',
    '3600': '1 hour',
    '7200': '2 hours',
    '86400': '1 day'
};

var currencySymbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
};

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const logos = {
    default: EvaDarkLogo,
    guc: EvaDarkLogo,
    kiko: KikoLogo,
    ajax: AjaxLogo
}

const colors = {
    default: 'indigo',
    guc: 'indigo',
    kiko: 'pink',
    ajax: 'red'
}

const gradients = {
    default: ['from-indigo-900 to-indigo-500', 'from-sky-900 to-sky-500'],
    guc: ['from-indigo-900 to-indigo-500', 'from-sky-900 to-sky-500'],
    kiko: ['from-pink-900 to-pink-500', 'from-rose-900 to-rose-500'],
    ajax: ['from-red-900 to-red-500', 'from-rose-900 to-rose-500']
}

export default function Dashboard() {
    const [theme, setTheme] = useState('dark');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [metricMap, setMetricMap] = useState(tabs[defaultTab].name);
    const [metricGraph, setMetricGraph] = useState(tabs[defaultTab].name);
    const [dates, setDates] = useState({
        startDate: new Date().setHours(0),
        endDate: new Date().setHours(24),
    });
    const [organizationUnitSets, setOrganizationUnitSets] = useState([]);
    const [organizationUnitSet, setOrganizationUnitSet] = useState(null);
    const [organizationUnits, setOrganizationUnits] = useState([]);
    const [organizationUnit, setOrganizationUnit] = useState(null);
    const [kpis, setKpis] = useState({});
    const [similarKpis, setSimilarKpis] = useState({});
    const [payments, setPayments] = useState({ categories: [0], values: [0] });
    const [categories, setCategories] = useState({ categories: [0], values: [0] });
    const [customers, setCustomers] = useState({ new: 0, returning: 0 });
    const [timelineData, setTimelineData] = useState({ categories: [0], values: [0] });
    const [similarTimelineData, setSimilarTimelineData] = useState({ categories: [0], values: [0] });
    const [countryData, setCountryData] = useState([]);
    const [filterDatepart, setFilterDatepart] = useState('hour');
    const [refresh, setRefresh] = useState(crypto.randomUUID());
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [selectedRefresh, setSelectedRefresh] = useState(null);
    const [chart, setChart] = useState('line');
    const [content, setContent] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [seletedCustomerType, setSelectedCustomerType] = useState(null);
    const [selectedBarDates, setSelectedBarDates] = useState(null);
    const [similar, setSimilar] = useState(true);
    const [signedIn, setSignedIn] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [search, setSearch] = useState(false);
    let [ouDialog, setOuDialog] = useState(false)
    const [selectedOuDialog, setSelectedOuDialog] = useState(null);
    const [dashboardOverview, setDashboardOverview] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' });
    const [chat, setChat] = useState(null);
    const [chats, setChats] = useState([]);
    const [chatOverview, setChatOverview] = useState(false);
    const [resetChat, setResetChat] = useState(null);
    const [deleteChat, setDeleteChat] = useState(false);
    const [dashboards, setDashboards] = useState([]);
    const [dashboard, setDashboard] = useState(null);
    const [deleteDashboard, setDeleteDashboard] = useState(false);
    const [shareDashboard, setShareDashboard] = useState(false);
    const [accessDashboard, setAccessDashboard] = useState(false);
    const [titleDashboard, setTitleDashboard] = useState(false);
    const [addDashboard, setAddDashboard] = useState(false);

    const [loadingKpi, setLoadingKpi] = useState(false);
    const [loadingPayments, setLoadingPayments] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [loadingConsumers, setLoadingConsumers] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const { brand } = useParams();

    const transformData = (data, key, value, sorted, sort_value, sort_desc, top) => {
        let sortedData = data;
        if (sorted) {
            if (sort_desc) {
                sortedData = data.sort((a, b) => b[sort_value].toString().replace(/-/g, '') - a[sort_value].toString().replace(/-/g, ''));
            }
            else {
                sortedData = data.sort((a, b) => a[sort_value].toString().replace(/-/g, '').replace(/[-T]/g, '') - b[sort_value].toString().replace(/-/g, '').replace(/[-T]/g, ''));
            }
        }

        // Filter out items with a value of 0
        const filteredData = sortedData.filter(item => item[value] !== 0);

        var topItems = filteredData;
        if (top !== 0) {
            topItems = filteredData.slice(0, top);
        };
        const categories = topItems.map(item => item[key]);
        const values = topItems.map(item => item[value]);

        const result = {
            values: values,
            categories: categories
        };

        return result
    }

    function fillMissingCategories(obj, allCategories) {
        const filledValues = [];

        allCategories.forEach(category => {
            const index = obj.categories.indexOf(category);
            if (index !== -1) {
                filledValues.push(obj.values[index]);
            } else {
                filledValues.push(0);
            }
        });

        var data = {
            values: filledValues,
            categories: Array.from(allCategories)
        }

        var sortedData = data.categories.map((category, index) => ({
            value: data.values[index],
            category: category
        }));
        sortedData.sort((a, b) => a.category - b.category);
        sortedData = {
            values: sortedData.map(item => item.value),
            categories: sortedData.map(item => item.category)
        };

        return sortedData;
    }

    function combineDatasets(dataset1, dataset2) {
        // Create a set of all categories from both objects
        const allCategories = new Set([...dataset1.categories, ...dataset2.categories]);

        const newDataset1 = fillMissingCategories(dataset1, allCategories);
        const newDataset2 = fillMissingCategories(dataset2, allCategories);

        return [newDataset1, newDataset2];
    }

    useEffect(() => {
        // Check if token exists in local storage
        if (!token) {
            // Redirect user to sign-in page if token doesn't exist
            localStorage.removeItem('token');
            window.location.reload();
        }
        else {
            const fetchData = async () => {
                try {
                    const api_url = url[brand] ? url[brand] : url['default']
                    const response = await fetch('https://' + api_url + '/message/Ping', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token
                        },
                    });
                    if (response.ok) {
                        const user_response = await fetch('https://' + api_url + '/api/core/GetCurrentUser', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': token
                            },
                        });
                        if (user_response.ok) {
                            const userData = await user_response.json();
                            setUser(userData?.User)
                            if (userData?.User?.FirstName) {
                                Notification('info', 'Welcome back ' + userData.User.FirstName + '!', 'Check now the performance of today.');
                            }
                            else {
                                Notification('info', 'Welcome back!', 'Check now the performance of today.');
                            }
                            setSignedIn(true);
                        }
                    }
                    else {
                        localStorage.removeItem('token');
                        window.location.reload();
                        Notification('error', 'Login expired', 'Please sign in again to access the dashboard.');
                        setSignedIn(false);
                    }
                } catch (error) {
                    localStorage.removeItem('token');
                    window.location.reload();
                    Notification('error', 'Something went wrong!', 'Please sign in again or contact support.');
                    setSignedIn(false);
                }
            };
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchOrganizationUnitSets = async () => {
            try {
                const api_url = url[brand] ? url[brand] : url['default']
                const response = await fetch('https://' + api_url + '/message/ListOrganizationUnitSets', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify({
                        "PageConfig": {
                            "Start": 0,
                            "Limit": 1000,
                            "SortDirection": 0,
                            "SortProperty": "Name",
                            "Filter": {
                                "Types": [1],
                            }
                        }
                    })
                });
                if (response.ok) {
                    const data = await response.json();
                    setOrganizationUnitSets(data.Result.Page)
                }
            } catch (error) {
                console.log(error)
            }
        };
        if (signedIn) { fetchOrganizationUnitSets() }
    }, [signedIn]);

    useEffect(() => {
        const fetchOrganizationUnits = async () => {
            setOrganizationUnit(null); // empty single OU
            try {
                if (organizationUnitSet?.ID > 0) {
                    const api_url = url[brand] ? url[brand] : url['default']
                    const response = await fetch('https://' + api_url + '/message/GetOrganizationUnitSetDetails', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token
                        },
                        body: JSON.stringify({
                            "ID": organizationUnitSet.ID
                        })
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setOrganizationUnits(data.Set.Subsets);
                    }
                }
                else {
                    const api_url = url[brand] ? url[brand] : url['default']
                    const response = await fetch('https://' + api_url + '/message/ListOrganizationUnitsDetailed', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token
                        },
                        body: JSON.stringify({
                            "Excluded": 7,
                            "PageConfig": {
                                "Start": 0,
                                "Limit": 1000,
                                "SortDirection": 0,
                                "SortProperty": "ParentID",
                                // "Filter": {
                                //     "Functionality": "Orders",
                                //     "FunctionalityScope": 8
                                // }
                            }
                        })
                    });
                    if (response.ok) {
                        const data = await response.json();

                        // Create organization unit tree
                        const nodes = data.Result.Page;
                        const map = {};
                        const roots = [];
                        nodes.forEach(node => {
                            const newNode = { ...node, Children: [] };
                            map[node.ID] = newNode;
                            if (node.ParentID === -1) {
                                roots.push(newNode);
                            } else {
                                const parent = map[node.ParentID];
                                if (parent) {
                                    parent.Children.push(newNode);
                                    newNode.parent = parent;
                                }
                                else {
                                    roots.push(newNode);
                                }
                            }
                        });

                        setOrganizationUnits(roots);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };
        if (signedIn) { fetchOrganizationUnits() }
    }, [signedIn, organizationUnitSet]);

    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchKPI = async () => {
            try {
                setLoadingKpi(true);
                const kpiData = await getKpis(ous, dates, {
                    CountryID: selectedCountry,
                    PaymentMethod: selectedPaymentMethod,
                    Product: selectedProduct,
                    Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                    BarDates: selectedBarDates
                });

                if (similar) {
                    const millisecondsInDay = 24 * 60 * 60 * 1000;
                    const timeDifference = Math.abs(dates.endDate - dates.startDate);
                    const daysToSubtract = Math.ceil(timeDifference / millisecondsInDay);
                    var similarFilterDates = {
                        startDate: new Date(dates.startDate - millisecondsInDay * daysToSubtract).setHours(0),
                        endDate: dates.startDate,
                    };

                    const similarKpiData = await getKpis(ous, similarFilterDates, {
                        CountryID: selectedCountry,
                        PaymentMethod: selectedPaymentMethod,
                        Product: selectedProduct,
                        Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                        BarDates: selectedBarDates
                    });

                    setKpis(kpiData);
                    setSimilarKpis(similarKpiData)
                }
                else {
                    setKpis(kpiData);
                    setSimilarKpis({})
                }

                setLoadingKpi(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchKPI() }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, selectedCountry, selectedPaymentMethod, selectedProduct, seletedCustomerType, selectedBarDates, similar]);

    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchCategories = async () => {
            try {
                setLoadingProducts(true);
                var categoryData = await getCategories(ous, dates, {
                    CountryID: selectedCountry,
                    PaymentMethod: selectedPaymentMethod,
                    Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                    BarDates: selectedBarDates,
                    Product: selectedProduct
                });
                categoryData = transformData(categoryData, 'Product', 'Ordered', true, 'Ordered', true, 10)
                setCategories(categoryData);
                setLoadingProducts(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchCategories() }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, selectedCountry, selectedPaymentMethod, seletedCustomerType, selectedBarDates, selectedProduct]);


    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchCustomers = async () => {
            try {
                setLoadingConsumers(true);
                var customerData = await getCustomers(ous, dates, {
                    CountryID: selectedCountry,
                    Product: selectedProduct,
                    PaymentMethod: selectedPaymentMethod,
                    BarDates: selectedBarDates
                });
                setCustomers(customerData);
                setLoadingConsumers(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchCustomers() }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, selectedCountry, selectedPaymentMethod, selectedProduct, selectedBarDates]);


    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchPayments = async () => {
            try {
                setLoadingPayments(true);
                var paymentData = await getPayments(ous, dates, {
                    CountryID: selectedCountry,
                    Product: selectedProduct,
                    Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                    BarDates: selectedBarDates
                });
                paymentData = transformData(paymentData, 'Method', 'Payments', true, 'Payments', true, 10)
                setPayments(paymentData);
                setLoadingPayments(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchPayments() }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, selectedCountry, selectedProduct, seletedCustomerType, selectedBarDates]);

    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchTimelineStats = async (filterDates) => {
            try {
                setLoadingOrders(true);
                var data = await getTimelineData(metricGraph, filterDatepart, ous, filterDates, {
                    CountryID: selectedCountry,
                    PaymentMethod: selectedPaymentMethod,
                    Product: selectedProduct,
                    Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                });
                data = transformData(data, 'timestamp_part', 'value', true, 'timestamp_part', false, 0);

                if (filterDatepart === 'weekday') {
                    const weekdaysArray = data.categories.map(number => {
                        const dayIndex = (number) % 7;
                        return weekdays[dayIndex];
                    });
                    data.categories = weekdaysArray;
                }

                if (similar) {
                    const millisecondsInDay = 24 * 60 * 60 * 1000;
                    const timeDifference = Math.abs(dates.endDate - dates.startDate);
                    const daysToSubtract = Math.ceil(timeDifference / millisecondsInDay);
                    var similarFilterDates = {
                        startDate: new Date(dates.startDate - millisecondsInDay * daysToSubtract).setHours(0),
                        endDate: dates.startDate,
                    };

                    var similarData = await getTimelineData(metricGraph, filterDatepart, ous, similarFilterDates, {
                        CountryID: selectedCountry,
                        PaymentMethod: selectedPaymentMethod,
                        Product: selectedProduct,
                        Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                    });
                    similarData = transformData(similarData, 'timestamp_part', 'value', true, 'timestamp_part', false, 0);

                    if (filterDatepart === 'weekday') {
                        const weekdaysArray = similarData.categories.map(number => {
                            const dayIndex = (number) % 7;
                            return weekdays[dayIndex];
                        });
                        similarData.categories = weekdaysArray;
                    }

                    var dataset = combineDatasets(data, similarData)
                    setTimelineData(dataset[0])
                    setSimilarTimelineData(dataset[1])
                }
                else {
                    setTimelineData(data)
                    setSimilarTimelineData({ categories: [null], values: [null] })
                }
                setLoadingOrders(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchTimelineStats(dates) }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, metricGraph, filterDatepart, selectedCountry, selectedPaymentMethod, selectedProduct, seletedCustomerType, similar]);

    useEffect(() => {
        var ous = []
        if (organizationUnit?.OrganizationUnitIds && organizationUnit.OrganizationUnitIds.length > 0) {
            ous = [organizationUnit?.OrganizationUnitIds]
        }
        else if (organizationUnit?.ID === undefined && organizationUnitSet) {
            ous = organizationUnits.map(item => item.OrganizationUnitID);
        }

        const fetchCountryStats = async () => {
            try {
                setLoadingCountries(true);
                var data = await getCountryData(metricMap, ous, dates, {
                    PaymentMethod: selectedPaymentMethod,
                    Product: selectedProduct,
                    Customers: seletedCustomerType ? seletedCustomerType === 'New' ? customers.new_customers : seletedCustomerType === 'Returning' ? customers.returning_customers : [seletedCustomerType] : null,
                    BarDates: selectedBarDates
                });
                setCountryData(data);
                setLoadingCountries(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (signedIn) { fetchCountryStats() }
    }, [signedIn, refresh, organizationUnitSet, organizationUnits, organizationUnit, dates, metricMap, selectedPaymentMethod, selectedProduct, seletedCustomerType, selectedBarDates]);

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const response = await fetch('https://ca-eva-databot-api-test.livelyfield-c1b731eb.westeurope.azurecontainerapps.io/api/v1/chatbot/GetConversations?user_id=' + user.EmailAddress, {
                    headers: {
                        'x-api-key': 'SIGa5tQhmF',
                        "Content-Type": "application/json",
                        'Accept': 'text/event-stream'
                    },
                    withCredentials: false,
                });
                if (response.ok) {
                    const data = await response.json();
                    setChats(data.conversations);
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (chatOverview) { fetchConversations() }
    }, [chatOverview]);

    useEffect(() => {
        const fetchDashboards = async () => {
            try {
                var dashboardData = await getDashboards(user.EmailAddress);
                setDashboards(dashboardData);
            } catch (error) {
                console.log(error);
            }
        };

        if (dashboardOverview) { fetchDashboards() }
    }, [dashboardOverview]);

    useEffect(() => {
        const fetchDashboard = async () => {
            try {
                var dashboardData = await getDashboard(accessDashboard?.id, accessDashboard?.creator);
                setDashboard(dashboardData);
            } catch (error) {
                console.log(error);
            }
        };

        if (accessDashboard) { fetchDashboard() }
    }, [accessDashboard]);

    const handleChatDeletion = async (user, chat) => {
        try {
            const params = {
                chat_id: chat,
                user_id: user,
                stream: false,
                skip_user_info: true,
                skip_conversation_title: true
            };
            const cleanedParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== null));
            const paramsCleaned = queryString.stringify(cleanedParams);

            const response = await fetch('https://ca-eva-databot-api-test.livelyfield-c1b731eb.westeurope.azurecontainerapps.io/api/v1/chatbot/GetAnswer?' + paramsCleaned, {
                method: "POST",
                headers: {
                    'x-api-key': 'SIGa5tQhmF',
                    "Content-Type": "application/json",
                    'Accept': 'text/event-stream'
                },
                body: JSON.stringify({ "message": "clear" })
            });
            if (response.ok) {
                Notification('success', 'Conversation deleted.', 'Your conversation is successfully deleted')
            }
            else {
                Notification('error', 'Something went wrong.', 'An error occured when deleting your dashboard, please try again or contact support.')
            }
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when deleting your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    const handleDashboardDeletion = async (id, creator) => {
        try {
            await patchDeleteDashboard(id, creator)
            Notification('success', 'Dashboard deleted.', 'Your dashboard is successfully deleted')
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when deleting your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    const handleDashboardSharing = async (id, creator, user, operation, rights) => {
        try {
            await patchDashboardAccessRights(id, creator, user, operation, rights)
            Notification('success', 'Updated access level.', 'The access level of ' + user + ' to your dashboard is successfuly modified.')
            // Update data
            var dashboardData = await getDashboard(id, creator);
            setDashboard(dashboardData);
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when sharing your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    const handleDashboardTitle = async (id, creator, title) => {
        try {
            await changeDashboardTitle(id, creator, title)
            Notification('success', 'Changed title.', 'The title of your dashboard is succesfully changed to ' + title)
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when changing the title of your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    const handleSignOut = (e) => {
        localStorage.removeItem('token');
        Notification('success', 'Goodbye!', 'Successfully signed out.')
        window.location.reload();
    };

    function switchTheme() {
        setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
        document.body.classList.toggle("dark");
    }

    function handleChat() {
        setShowChat(prevShowChat => !prevShowChat);
    }

    const switchMapMetricValue = (data) => {
        setMetricMap(data);
    };

    const switchMapGraphValue = (data) => {
        setMetricGraph(data);
    };

    const handleDateTimeChange = newValue => {
        newValue.startDate = new Date(newValue.startDate).getTime();
        newValue.endDate = new Date(newValue.endDate).getTime() + (24 * 60 * 60 * 1000);
        setDates(newValue);
    };

    const handleRefresh = () => {
        setRefresh(crypto.randomUUID());
    }

    // Function to handle selecting an interval
    const handleIntervalChange = (data) => {
        clearInterval(refreshInterval);

        if (data !== 'off') {
            const intervalId = setInterval(() => {
                handleRefresh();
            }, parseInt(data) * 1000);
            setRefreshInterval(intervalId);
        }
        setSelectedRefresh(data);
    };

    const resetFilters = () => {
        setDates({
            startDate: new Date().setHours(0),
            endDate: new Date().setHours(24),
        });
        setOrganizationUnit(null);
        setOrganizationUnitSet(null);
        setSelectedPaymentMethod(null);
        setSelectedProduct(null);
        setSelectedCustomerType(null);
        setSelectedCountry(null);
        setSelectedBarDates(null);
    }

    const onHandleSearch = (topic) => {
        try {
            setSearch(topic);
        }
        catch (error) {
            console.log(error);
            setSearch(false);
        }
    }

    const collectOuIds = (unit) => {
        let ids = [unit.ID];
        if (unit.Children && unit.Children.length > 0) {
            for (let child of unit.Children) {
                ids = ids.concat(collectOuIds(child));
            }
        }
        return ids;
    };

    const handleOuSelect = (ou) => {
        console.log(ou)
        const ids = collectOuIds(ou);
        ou.OrganizationUnitIds = ids;
        setOrganizationUnit(ou);
        setOuDialog(false);
    };

    const calculateDifference = (current, similar) => {
        if (typeof current !== 'number' || typeof similar !== 'number' || current === 0) return null;
        const difference = ((current - similar) / Math.abs(similar)) * 100;
        if (isNaN(difference) || !isFinite(difference)) return null;
        const roundedDifference = Math.round(difference);
        return `${roundedDifference > 0 ? '+' : ''}${roundedDifference}%`;
    };

    const ChartComponent = chart === 'bar' ? ComboChart : AreaChart;

    const handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'D') {
            switchTheme();
        }
        else if (event.ctrlKey && event.key === 'T') {
            console.log(showChat);
            handleChat();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const applyFilters = (filters) => {
        filters.forEach((filter) => {
            if (filter.name === 'reset_filters') { resetFilters(); };
            if (filter.name === 'refresh') { setRefresh(filter.value); };
            if (filter.name === 'refresh_rate') { handleIntervalChange(filter.value); };
            if (filter.name === 'similar_period') { setSimilar(filter.value); };
            if (filter.name === 'date_range') { handleDateTimeChange({ startDate: filter.value.from, endDate: filter.value.to }); };
            if (filter.name === 'product') { setSelectedProduct(filter.value); };
            if (filter.name === 'consumer') { setSelectedCustomerType(filter.value); };
            if (filter.name === 'country') { setSelectedCountry(filter.value); };
            if (filter.name === 'organization_unit') { handleOuSelect(filter.value); };
            if (filter.name === 'x_axis') { setFilterDatepart(filter.value); };
            if (filter.name === 'dark_mode' && ((filter.value && theme === 'light') || (!filter.value && theme === 'dark'))) { switchTheme(); };
        });
    };

    const handleAddDashboard = async () => {
        try {
            const title = document.getElementById('dashboard-new').value;
            var response = await createDashboard(user.EmailAddress, title);
            Notification('success', 'Dashboard created.', title + ' is successfully added to your dahsboards.')
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when adding the chart your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    return (
        <>
            {signedIn ?
                <div className='flex xl:h-screen w-screen bg-gradient-to-br from-zinc-100 dark:from-zinc-800 from-5% to-zinc-300 dark:to-black to-60%'>
                    <main className='flex flex-col mx-4 mb-4 lg:mx-6 lg:mb-6 w-screen'>

                        <Dialog open={dashboardOverview} onClose={setDashboardOverview} size="3xl">
                            <DialogTitle className="text-sm">Dashboards</DialogTitle>
                            <DialogDescription className="text-sm">Below is a list of dashboards you can access and/or edit.</DialogDescription>
                            <DialogBody>
                                <Table bleed compact>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeader>Name</TableHeader>
                                            <TableHeader>Creator</TableHeader>
                                            <TableHeader>Role</TableHeader>
                                            <TableHeader></TableHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key='0'>
                                            <TableCell className="font-medium dark:text-white">Event Dashboard</TableCell>
                                            <TableCell className="dark:text-white">In2Intel</TableCell>
                                            <TableCell className="text-zinc-500">View</TableCell>
                                            <TableCell className="text-zinc-500">
                                                <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                                    <Dropdown>
                                                        <DropdownButton plain aria-label="More options">
                                                            <EllipsisHorizontalIcon />
                                                        </DropdownButton>
                                                        <DropdownMenu anchor="bottom end">
                                                            <DropdownItem className="text-sm" href="/dashboard">View</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {dashboards.map((dashboardItem, index) => (
                                            <TableRow key={index + 2}>
                                                <TableCell className="font-medium dark:text-white">{dashboardItem?.title}</TableCell>
                                                <TableCell className="dark:text-white">{dashboardItem?.creator}</TableCell>
                                                <TableCell className="text-zinc-500">{dashboardItem?.creator === user.EmailAddress ? 'Admin' : dashboardItem?.access[0]?.charAt(0)?.toUpperCase() + dashboardItem?.access[0]?.slice(1)}</TableCell>
                                                <TableCell className="text-zinc-500">
                                                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                                        <Dropdown>
                                                            <DropdownButton plain aria-label="More options">
                                                                <EllipsisHorizontalIcon />
                                                            </DropdownButton>
                                                            <DropdownMenu anchor="bottom end">
                                                                <DropdownItem className="text-sm" href={"/report/" + (brand ? brand : 'guc') + "/" + dashboardItem.id}>View</DropdownItem>
                                                                {dashboardItem?.access?.includes('admin') || dashboardItem?.creator === user.EmailAddress
                                                                    ? <DropdownItem className="text-sm" onClick={() => { setTitleDashboard(dashboardItem); setDashboardOverview(false) }}>Edit title</DropdownItem>
                                                                    : null}
                                                                {dashboardItem?.access?.includes('admin') || dashboardItem?.creator === user.EmailAddress
                                                                    ? <DropdownItem className="text-sm" onClick={() => { setShareDashboard(dashboardItem); setDashboardOverview(false) }}>Share</DropdownItem>
                                                                    : null}
                                                                {dashboardItem?.access?.includes('admin') || dashboardItem?.creator === user.EmailAddress
                                                                    ? <DropdownItem className="text-sm" onClick={() => { setAccessDashboard(dashboardItem); setDashboardOverview(false) }}>Manage access</DropdownItem>
                                                                    : null}
                                                                {dashboardItem?.access?.includes('admin') || dashboardItem?.creator === user.EmailAddress
                                                                    ? <DropdownItem className="text-sm" onClick={() => { setDeleteDashboard(dashboardItem); setDashboardOverview(false) }}>Delete</DropdownItem>
                                                                    : null}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </DialogBody>
                            <DialogActions>
                                <Button className="text-xs sm:text-md" plain onClick={() => setDashboardOverview(false)}>Close</Button>
                                <Button className="text-xs sm:text-md" onClick={() => {setAddDashboard(true); setDashboardOverview(false);}}>Add dashboard</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={addDashboard} onClose={() => {setDashboardOverview(true); setAddDashboard(false);}}>
                            <DialogTitle>Add dashboard</DialogTitle>
                            <DialogDescription>
                                Define a title for your new dashboard.
                            </DialogDescription>
                            <DialogBody>
                                <Field>
                                    <Label>Title</Label>
                                    <Input id="dashboard-new" name="dashboard" defaultValue={"Dashboard of " + user?.FullName} />
                                </Field>
                            </DialogBody>
                            <DialogActions>
                                <Button plain onClick={() => {setAddDashboard(false); setDashboardOverview(true)}}>
                                    Close
                                </Button>
                                <Button onClick={() => { handleAddDashboard(); setAddDashboard(false); setDashboardOverview(true) }}>
                                    Add dashboard
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Alert open={deleteDashboard ? true : false} onClose={() => setDeleteDashboard(null)}>
                            <AlertTitle>Are you sure you want to delete this dashboard?</AlertTitle>
                            <AlertDescription>
                                This will delete the dashboard '{deleteDashboard?.title}'
                            </AlertDescription>
                            <AlertActions>
                                <Button plain onClick={() => { setDashboardOverview(true); setDeleteDashboard(null) }}>
                                    Cancel
                                </Button>
                                <Button color="red" onClick={() => { handleDashboardDeletion(deleteDashboard?.id, deleteDashboard?.creator); setDashboardOverview(true); setDeleteDashboard(null) }}>Delete</Button>
                            </AlertActions>
                        </Alert>

                        <Dialog open={titleDashboard ? true : false} onClose={() => setTitleDashboard(null)}>
                            <DialogTitle>Change title</DialogTitle>
                            <DialogDescription>
                                Change the title of dashboard '{titleDashboard?.title}'.
                            </DialogDescription>
                            <DialogBody>
                                <Field>
                                    <Label>Title</Label>
                                    <Input id="dashboard-title" name="title" defaultValue={titleDashboard?.title} autoFocus />
                                </Field>
                            </DialogBody>
                            <DialogActions>
                                <Button plain onClick={() => { setDashboardOverview(true); setTitleDashboard(null) }}>
                                    Cancel
                                </Button>
                                <Button onClick={() => { handleDashboardTitle(titleDashboard.id, titleDashboard.creator, document.getElementById('dashboard-title').value); setDashboardOverview(true); setTitleDashboard(null) }}>Save</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={shareDashboard ? true : false} onClose={() => setShareDashboard(null)}>
                            <DialogTitle>Share dashboard</DialogTitle>
                            <DialogDescription>
                                To share '{shareDashboard?.title}' with others, please enter their email address and specify the access level.
                            </DialogDescription>
                            <DialogBody>
                                <Field>
                                    <Label>Email</Label>
                                    <Input id="dashboard-email" name="email" autoFocus />
                                </Field>
                                <Field className="mt-6">
                                    <Label>Access level</Label>
                                    <Select id="dashboard-access-level" name="access">
                                        <option value="view">View</option>
                                        <option value="edit">Edit</option>
                                        <option value="admin">Admin</option>
                                    </Select>
                                </Field>
                            </DialogBody>
                            <DialogActions>
                                <Button plain onClick={() => { setDashboardOverview(true); setShareDashboard(null) }}>
                                    Cancel
                                </Button>
                                <Button onClick={() => { handleDashboardSharing(shareDashboard?.id, shareDashboard?.creator, document.getElementById("dashboard-email").value, 'addOrReplace', [document.getElementById("dashboard-access-level").value]); setDashboardOverview(true); setShareDashboard(null) }}>Share</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={accessDashboard ? true : false} onClose={() => setAccessDashboard(null)}>
                            <DialogTitle>Edit access rights</DialogTitle>
                            <DialogDescription>
                                To edit the access rights for '{accessDashboard?.title}', please select their email address and specify the access level.
                            </DialogDescription>
                            <DialogBody>
                                {dashboard?.access
                                    ? Object.keys(dashboard?.access)?.map((userAccessEmail, index) => (
                                        <Field className="flex align-middle items-start w-full">
                                            <Label className="mr-4 mt-4 overflow-x-scroll w-full">{userAccessEmail}</Label>
                                            <Select id="dashboard-access-level" name="access" onChange={(event) => handleDashboardSharing(dashboard?.id, dashboard?.user, userAccessEmail, 'addOrReplace', [event.target.value])}>
                                                <option value="view" selected={dashboard?.access[userAccessEmail]?.rights?.includes('view')} >View</option>
                                                <option value="edit" selected={dashboard?.access[userAccessEmail]?.rights?.includes('edit')} >Edit</option>
                                                <option value="admin" selected={dashboard?.access[userAccessEmail]?.rights?.includes('admin')} >Admin</option>
                                            </Select>
                                            <Button className="ml-1 mt-3" plain onClick={() => handleDashboardSharing(dashboard?.id, dashboard?.user, userAccessEmail, 'delete', null)} >
                                                <TrashIcon />
                                            </Button>
                                        </Field>
                                    ))
                                    : null}
                            </DialogBody>
                            <DialogActions>
                                <Button plain onClick={() => { setDashboardOverview(true); setAccessDashboard(null) }}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={chatOverview} onClose={() => setChatOverview(false)} size="3xl">
                            <DialogTitle className="text-sm">Chats</DialogTitle>
                            <DialogDescription className="text-sm">Below is a list of all your chats.</DialogDescription>
                            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white max-h-96 overflow-y-scroll overflow-x-hidden">
                                {chats.map((conversation, index) => (
                                    <div key={index} className="flex justify-between items-center mt-1">
                                        <p onClick={() => { setChat(conversation.id); setChatOverview(false); setShowChat(true); }} className={`hover:bg-zinc-100 dark:hover:bg-zinc-800 w-full rounded-lg pl-2 py-2 ${conversation?.id === chat ? 'bg-zinc-300 dark:bg-zinc-700' : ''}`}>{conversation.title}</p>
                                        <Button plain onClick={() => { setDeleteChat(conversation); setChatOverview(false) }}>
                                            <TrashIcon />
                                        </Button>
                                    </div>
                                ))}
                            </DialogBody>
                            <DialogActions>
                                <Button className="text-xs sm:text-md" plain onClick={() => setChatOverview(false)}>Close</Button>
                                <Button className="text-xs sm:text-md" onClick={() => { setResetChat(crypto.randomUUID()); setShowChat(true); setChatOverview(false); }}>New chat</Button>
                            </DialogActions>
                        </Dialog>

                        <Alert open={deleteChat ? true : false} onClose={() => setDeleteChat(null)}>
                            <AlertTitle>Are you sure you want to delete this chat?</AlertTitle>
                            <AlertDescription>
                                This will delete the chat '{deleteChat?.title}'
                            </AlertDescription>
                            <AlertActions>
                                <Button plain onClick={() => { setChatOverview(true); setDeleteChat(null) }}>
                                    Cancel
                                </Button>
                                <Button color="red" onClick={() => { handleChatDeletion(user.EmailAddress, deleteChat?.id); setChatOverview(true); setDeleteChat(null) }}>Delete</Button>
                            </AlertActions>
                        </Alert>

                        {search
                            ? <Search
                                onSearchChange={onHandleSearch}
                                apiUrl={url[brand] ? url[brand] : url['default']}
                                apiService={search === 'products' ? 'SearchProducts' : search === 'users' ? 'SearchUsers' : null}
                                setFilter={search === 'products' ? setSelectedProduct : search === 'users' ? setSelectedCustomerType : null}
                            />
                            : null}

                        <div id="header" className='flex flex-col lg:flex-row h-fit lg:h-24 py-5 lg:py-0 w-full items-start lg:items-center justify-between'>
                            <a href="/home">
                                <img src={logos[brand] ? logos[brand] : logos['default']} alt="EVA" className={`${['ajax'].includes(brand) ? 'h-12' : 'h-7'} w-auto mb-6 lg:mb-0 ${theme === 'dark' ? 'invert' : ''}`}></img>
                            </a>
                            <div className='flex flex-col lg:flex-row gap-x-2 gap-y-1 lg:gapy-0 items-center w-full lg:w-fit'>
                                <div className='w-full lg:w-fit lg:min-w-60'>
                                    <Datepicker
                                        startWeekOn="mon"
                                        primaryColor={colors[brand] ? colors[brand] : colors['default']}
                                        separator={"to"}
                                        placeholder={"Date Range"}
                                        value={dates}
                                        onChange={handleDateTimeChange}
                                        showShortcuts={true}
                                        configs={{
                                            shortcuts: {
                                                today: "Today",
                                                yesterday: "Yesterday",
                                                past: period => `Last ${period} days`,
                                                currentMonth: "This month",
                                                pastMonth: "Last month",
                                            }
                                        }}
                                        showFooter={true}
                                        inputClassName={clsx([
                                            // Basic layout
                                            'relative w-full appearance-none rounded-lg py-[calc(theme(spacing[2.5])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
                                            // Set minimum height for when no value is selected
                                            'min-h-11 sm:min-h-9',
                                            // Horizontal padding
                                            'pl-[calc(theme(spacing[3.5])-1px)] pr-[calc(theme(spacing.7)-1px)] sm:pl-[calc(theme(spacing.3)-1px)]',
                                            // Typography
                                            'text-left text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white forced-colors:text-[CanvasText]',
                                            // Border
                                            'border border-zinc-950/25 hover:border-zinc-950/35 dark:border-white/10 dark:group-data-[active]:border-white/20 dark:hover:border-white/20',
                                            // Background color
                                            'bg-transparent bg-white dark:bg-white/5'
                                        ])}
                                    />
                                </div>
                                <div className='flex w-full lg:w-fit'>
                                    <Button color={theme === 'dark' ? 'dark' : 'white'} onClick={() => setOuDialog(true)} className={`max-lg:absolute max-sm:right-28 max-lg:right-24 max-lg:top-3 max-lg:mr-2`}>
                                        <BuildingOffice2Icon />
                                        {organizationUnitSet || organizationUnit
                                            ? <span className='max-lg:hidden text-thin text-sm ml-1 font-normal'>
                                                {organizationUnit
                                                    ? organizationUnit.CompanyName ? organizationUnit.Name + '- ' + organizationUnit.CompanyName : organizationUnit.ParentName ? organizationUnit.Name + ' - ' + organizationUnit.ParentName : organizationUnit.Name
                                                    : organizationUnitSet ? organizationUnitSet.Name : null}
                                            </span>
                                            : null}
                                    </Button>
                                    <Dialog open={ouDialog} onClose={setOuDialog} size="xl">
                                        <DialogTitle className="text-sm">Select an Organization Unit Set</DialogTitle>
                                        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
                                            <div className=' w-full'>
                                                <Dropdown>
                                                    <DropdownButton color={theme === 'dark' ? 'dark' : 'white'} className={`w-full`}>
                                                        <span className={`text-sm mr-auto font-normal ${organizationUnitSet ? 'text-white dark:bg-zinc-900' : 'text-zinc-500'}`}>
                                                            {organizationUnitSet ? organizationUnitSet.Name : 'Organization Unit Set'}
                                                        </span>
                                                        <ChevronUpDownIcon />
                                                    </DropdownButton>
                                                    <DropdownMenu>
                                                        {organizationUnitSets.map((set, index) => (
                                                            <DropdownItem key={index} onClick={() => setOrganizationUnitSet(set)} className={`text-sm rouded-lg ${set?.ID === organizationUnitSet?.ID ? 'bg-zinc-200 dark:bg-zinc-700' : ''}`}>{set.Name}</DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </DialogBody>
                                        <DialogTitle className="text-sm mt-10">Select an Organization Unit</DialogTitle>
                                        <DialogDescription className="text-sm pb-4 border-b-[1px] border-b-zinc-100 dark:border-b-zinc-800">You can explore the data for any organizational unit.</DialogDescription>
                                        {selectedOuDialog ?
                                            <div className="flex justify-between items-center pt-2 sm:pt-6">
                                                <Button plain onClick={() => setSelectedOuDialog(selectedOuDialog.parent)}>
                                                    <ChevronLeftIcon />
                                                </Button>
                                                <p className='w-full rounded-lg m-auto text-center text-sm/6 text-zinc-900 dark:text-white'>
                                                    {selectedOuDialog?.CompanyName ? selectedOuDialog?.Name + '- ' + selectedOuDialog?.CompanyName : selectedOuDialog?.ParentName ? selectedOuDialog?.Name + ' - ' + selectedOuDialog?.ParentName : selectedOuDialog?.Name}
                                                </p>
                                                <Button plain disabled />
                                            </div>
                                            : null}
                                        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white max-h-44 sm:max-h-96 overflow-y-scroll overflow-x-hidden">
                                            {selectedOuDialog ? (
                                                selectedOuDialog.Children.map((ou, index) => (
                                                    <div key={index} className="flex justify-between items-center mt-1">
                                                        <p onClick={() => { handleOuSelect(ou); setOuDialog(false) }} className={`hover:bg-zinc-100 dark:hover:bg-zinc-800 w-full rounded-lg pl-2 py-2 ${ou?.ID === organizationUnit?.ID ? 'bg-zinc-300 dark:bg-zinc-700' : ''}`}>
                                                            {ou.CompanyName ? ou.Name + '- ' + ou.CompanyName : ou.ParentName ? ou.Name + ' - ' + ou.ParentName : ou.Name}
                                                        </p>
                                                        {ou.Children && ou.Children.length > 0 && (
                                                            <Button plain onClick={() => setSelectedOuDialog(ou)}>
                                                                <ChevronRightIcon />
                                                            </Button>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                organizationUnits.map((ou, index) => (
                                                    <div key={index} className="flex justify-between items-center mt-1">
                                                        <p onClick={() => { handleOuSelect(ou); setOuDialog(false) }} className={`hover:bg-zinc-100 dark:hover:bg-zinc-800 w-full rounded-lg pl-2 py-2 ${ou?.ID === organizationUnit?.ID ? 'bg-zinc-300 dark:bg-zinc-700' : ''}`}>
                                                            {ou.CompanyName ? ou.Name + '- ' + ou.CompanyName : ou.ParentName ? ou.Name + ' - ' + ou.ParentName : ou.Name}
                                                        </p>
                                                        {ou.Children && ou.Children.length > 0 && (
                                                            <Button plain onClick={() => setSelectedOuDialog(ou)}>
                                                                <ChevronRightIcon />
                                                            </Button>
                                                        )}
                                                    </div>
                                                ))
                                            )}
                                        </DialogBody>
                                        <DialogActions>
                                            <Button className="text-xs sm:text-md" plain onClick={() => { setOrganizationUnitSet(null); setOrganizationUnit(null); }}>Reset</Button>
                                            <Button className="text-xs sm:text-md" onClick={() => setOuDialog(false)}>Close</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                <div className='w-full lg:w-fit'>
                                    <Dropdown>
                                        <DropdownButton color={theme === 'dark' ? 'dark' : 'white'} className={`max-lg:absolute max-sm:right-16 max-lg:right-14 max-lg:top-3 max-lg:mr-1`}>
                                            <ClockIcon />
                                            {refreshInterval
                                                ? <span className='max-lg:hidden text-thin text-sm ml-1 font-normal'>
                                                    {refreshIntervals[selectedRefresh]}
                                                </span>
                                                : null}
                                        </DropdownButton>
                                        <DropdownMenu>
                                            {Object.entries(refreshIntervals)
                                                .map(([key, value]) => (
                                                    <DropdownItem key={key} onClick={() => handleIntervalChange(key)} className={` rouded-lg ${key === selectedRefresh ? 'bg-zinc-200 dark:bg-zinc-700' : ''}`}>
                                                        {value}
                                                    </DropdownItem>
                                                ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className='w-full lg:w-fit'>
                                    <Dropdown>
                                        <DropdownButton aria-label="More options" color={theme === 'dark' ? 'dark' : 'white'} className={`max-lg:absolute max-lg:right-4 max-lg:top-3 z-20`}>
                                            <Bars3Icon />
                                        </DropdownButton>
                                        <DropdownMenu anchor="bottom">
                                            <DropdownItem onClick={() => setDashboardOverview(true)}>
                                                <UserIconSolid />
                                                <DropdownLabel>My dashboards</DropdownLabel>
                                            </DropdownItem>
                                            <DropdownItem onClick={() => { setShowChat(false); setChatOverview(true); }}>
                                                <ChatBubbleOvalLeftEllipsisIconSolid />
                                                <DropdownLabel>My chats</DropdownLabel>
                                            </DropdownItem>
                                            <DropdownSeparator />
                                            <DropdownItem onClick={() => switchTheme()}>
                                                {theme === 'dark'
                                                    ? <MoonIconSolid />
                                                    : <SunIconSolid />
                                                }
                                                <DropdownLabel>{theme === 'dark' ? 'Dark mode' : 'Light mode'}</DropdownLabel>
                                                <DropdownShortcut keys="⌃⇧D" />
                                            </DropdownItem>
                                            <DropdownItem onClick={handleRefresh}>
                                                <ArrowPathIconSolid />
                                                <DropdownLabel>Refresh</DropdownLabel>
                                            </DropdownItem>
                                            <DropdownItem onClick={resetFilters}>
                                                <AdjustmentsHorizontalIconSolid />
                                                <DropdownLabel>Reset filters</DropdownLabel>
                                            </DropdownItem>
                                            <DropdownItem onClick={() => setSimilar(similar ? false : true)}>
                                                <ChartBarSquareIconSolid />
                                                <DropdownLabel>Similar period {similar ? "enabled" : "disabled"}</DropdownLabel>
                                            </DropdownItem>
                                            {showChat ? <DropdownSeparator /> : null}
                                            <DropdownItem onClick={() => setShowChat(showChat ? false : true)} className="px-4">
                                                <SparklesIconSolid />
                                                <DropdownLabel>{showChat ? 'Close AI chat' : 'Ask AI'}
                                                    <span className="inline-flex ml-2 w-11 text-center items-center rounded-md bg-indigo-200 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-purple-700/10">
                                                        New
                                                    </span>
                                                </DropdownLabel>
                                                <DropdownShortcut keys="⌃⇧T" />
                                            </DropdownItem>
                                            {showChat
                                                ? <DropdownItem onClick={() => setResetChat(crypto.randomUUID())}>
                                                    <PlusCircleIcon />
                                                    <DropdownLabel>New chat</DropdownLabel>
                                                </DropdownItem>
                                                : null}
                                            <DropdownSeparator />
                                            <DropdownItem onClick={handleSignOut}>
                                                <ArrowRightStartOnRectangleIconSolid />
                                                <DropdownLabel>Sign out</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div id="dashboard" className='w-full h-full'>
                            <div className='grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-5 sm:auto-rows-fr w-full xl:h-full gap-4'>
                                <div className='grid grid-cols-1 col-span-1 sm:col-span-4 xl:col-span-2 sm:row-span-2 sm:grid-cols-2 gap-4'>
                                    <div id='kpi-revenue' className='col-span-1 row-span-1'>
                                        <KPI
                                            title={'Revenue'}
                                            value={organizationUnit?.CurrencyID
                                                ? currencySymbols[organizationUnit?.CurrencyID] + (Math.round(kpis.Revenue * 100) / 100).toFixed(2)
                                                : '€' + (Math.round(kpis.Revenue * 100) / 100).toFixed(2)
                                            }
                                            background={'bg-gradient-to-br from-zinc-500 from-10% to-zinc-400 to-80%'}
                                            loading={loadingKpi}
                                            difference={calculateDifference(kpis.Revenue, similarKpis?.Revenue)}
                                        />
                                    </div>
                                    <div id='kpi-netrevenue' className='col-span-1 row-span-1 h-40 xl:h-full rounded-xl shadow'>
                                        <KPI
                                            title={'Net Revenue'}
                                            value={organizationUnit?.CurrencyID ? currencySymbols[organizationUnit?.CurrencyID] + (Math.round(kpis.NetRevenue * 100) / 100).toFixed(2) : '€' + (Math.round(kpis.NetRevenue * 100) / 100).toFixed(2)}
                                            background={`bg-gradient-to-br from-10% to-80% ${gradients[brand] ? gradients[brand][1] : gradients['default'][1]}`}
                                            loading={loadingKpi}
                                            difference={calculateDifference(kpis.NetRevenue, similarKpis?.NetRevenue)}
                                        />
                                    </div>
                                    <div id='kpi-orders' className={`col-span-1 row-span-1 h-40 xl:h-full rounded-xl shadow`}>
                                        <KPI
                                            title={'Orders'}
                                            value={Math.round(kpis.Orders)}
                                            background={`bg-gradient-to-br from-10% to-80% ${gradients[brand] ? gradients[brand][0] : gradients['default'][0]}`}
                                            loading={loadingKpi}
                                            difference={calculateDifference(kpis.Orders, similarKpis?.Orders)}
                                        />
                                    </div>
                                    <div id='kpi-orderamount' className='col-span-1 row-span-1 h-40 xl:h-full rounded-xl shadow bg-gradient-to-br from-gray-700 from-10% to-gray-400 to-80%'>
                                        <KPI
                                            title={'Average Order Amount'}
                                            value={kpis.AvgOrderAmount
                                                ? organizationUnit?.CurrencyID
                                                    ? currencySymbols[organizationUnit?.CurrencyID] + (Math.round(kpis.Revenue * 100) / 100).toFixed(2)
                                                    : '€' + (Math.round(kpis.AvgOrderAmount * 100) / 100).toFixed(2)
                                                : organizationUnit?.CurrencyID
                                                    ? currencySymbols[organizationUnit?.CurrencyID] + '0.00'
                                                    : '€0.00'
                                            }
                                            background={'bg-gradient-to-br from-zinc-700 from-10% to-zinc-400 to-80%'}
                                            loading={loadingKpi}
                                            difference={calculateDifference(kpis.AvgOrderAmount, similarKpis?.AvgOrderAmount)}
                                        />
                                    </div>
                                </div>
                                <div id='paymentmethods' className='col-span-1 row-span-2 sm:row-span-2 xl:row-span-2 sm:col-span-2 xl:col-span-1 h-96 sm:h-full rounded-xl shadow bg-zinc-100 dark:bg-zinc-900'>
                                    <div className='flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                        <CreditCardIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                        <span className='my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>Payment methods</span>
                                        {loadingPayments ? <div className="my-auto ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" /> : null}
                                    </div>
                                    <div className='px-3 w-full h-[calc(100%-71px)] items-center align-middle relative'>
                                        <BarChart
                                            showAxisLineY={false}
                                            showAxisLineX={true}
                                            horizontal={true}
                                            theme={theme}
                                            chartData={[
                                                {
                                                    name: "Payments",
                                                    data: payments.values,
                                                }
                                            ]}
                                            chartCategories={payments.categories}
                                            setValueFunction={setSelectedPaymentMethod}
                                            selectedValue={selectedPaymentMethod}
                                        />
                                        <div className="absolute top-0 right-1 ml-4">
                                            {selectedPaymentMethod
                                                ? <div className='flex items-center'>
                                                    <FunnelIcon className='w-3 h-3 text-zinc-400 mr-1' />
                                                    <span className="text-xs sm:text-xs font-heading text-zinc-400">{selectedPaymentMethod}</span>
                                                    <Button
                                                        color={theme === 'dark' ? 'dark' : 'white'}
                                                        onClick={() => setSelectedPaymentMethod(null)}
                                                        className="text-xs sm:text-xs"
                                                        plain
                                                    >
                                                        <XCircleIcon />
                                                    </Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id='products' className='col-span-1 sm:col-span-2 xl:col-span-1 row-span-2 sm:row-span-2 xl:row-span-2 h-96 sm:h-full rounded-xl shadow bg-zinc-100 dark:bg-zinc-900'>
                                    <div className='flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                        <TagIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                        <span className='my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>Products</span>
                                        {loadingProducts ? <div className="my-auto ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" /> : null}
                                        <MagnifyingGlassIcon onClick={() => onHandleSearch('products')} className='text-zinc-800 dark:text-white mr-5 my-auto w-4 align ml-auto' />
                                    </div>
                                    <div className='px-3 w-full h-[calc(100%-71px)] items-center align-middle relative'>
                                        <BarChart
                                            showAxisLineY={false}
                                            showAxisLineX={true}
                                            horizontal={true}
                                            theme={theme}
                                            chartData={[
                                                {
                                                    name: "Ordered",
                                                    data: categories.values,
                                                }
                                            ]}
                                            chartCategories={categories.categories}
                                            setValueFunction={setSelectedProduct}
                                            selectedValue={selectedProduct}
                                        />
                                        <div className="absolute top-0 right-1 ml-4">
                                            {selectedProduct
                                                ?
                                                <div className='flex items-center'>
                                                    <FunnelIcon className='w-3 h-3 text-zinc-400 mr-1' />
                                                    <span className="text-xs sm:text-xs font-heading text-zinc-400">{selectedProduct}</span>
                                                    <Button
                                                        color={theme === 'dark' ? 'dark' : 'white'}
                                                        onClick={() => setSelectedProduct(null)}
                                                        className="text-xs sm:text-xs"
                                                        plain
                                                    >
                                                        <XCircleIcon />
                                                    </Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id='consumers' className='col-span-1 sm:col-span-2 xl:col-span-1 row-span-2 sm:row-span-2 xl:row-span-2 h-96 sm:h-full rounded-xl shadow bg-zinc-100 dark:bg-zinc-900'>
                                    <div className='flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                        <UserIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                        <span className='my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>Consumers</span>
                                        {loadingConsumers ? <div className="my-auto ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" /> : null}
                                        <MagnifyingGlassIcon onClick={() => onHandleSearch('users')} className='text-zinc-800 dark:text-white mr-5 my-auto w-4 align ml-auto' />
                                    </div>
                                    <div className='px-3 py-3 w-full h-[calc(100%-71px)] items-center align-middle relative'>
                                        <CircleChart
                                            theme={theme}
                                            chartData={[customers.new, customers.returning]}
                                            chartLabels={['New', 'Returning']}
                                            setValueFunction={setSelectedCustomerType}
                                            selectedValue={seletedCustomerType}
                                            color={colors[brand] ? colors[brand] : colors['default']}
                                        />
                                        <div className="absolute top-0 right-1 ml-4">
                                            {seletedCustomerType
                                                ? <div className='flex items-center'>
                                                    <FunnelIcon className='w-3 h-3 text-zinc-400 mr-1' />
                                                    <span className="text-xs sm:text-xs font-heading text-zinc-400">{seletedCustomerType}</span>
                                                    <Button
                                                        color={theme === 'dark' ? 'dark' : 'white'}
                                                        onClick={() => setSelectedCustomerType(null)}
                                                        className="text-xs sm:text-xs"
                                                        plain
                                                    >
                                                        <XCircleIcon />
                                                    </Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id='revenue' className='col-span-1 sm:col-span-2 xl:col-span-2 row-span-3 sm:row-span-2 xl:row-span-3 h-96 sm:h-full rounded-xl shadow bg-zinc-100 dark:bg-zinc-900'>
                                    <div className='flex h-12 sm:h-11 xl:h-11 w-full rounded-t-lg bg-white dark:bg-zinc-800 items-center justify-between space-between'>
                                        <div className='flex w-40'>
                                            {metricMap === 'Orders'
                                                ? <ShoppingBagIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                                : metricMap === 'Revenue'
                                                    ? <BanknotesIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                                    : <UserIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                            }
                                            <span className='my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>{metricMap}</span>
                                            {loadingCountries ? <div className="my-auto ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" /> : null}
                                        </div>
                                        <div className='my-auto mr-2 sm:mr-0'>
                                            <Tabs tabs={tabs} defaultTab={defaultTab} setTabValue={switchMapMetricValue} />
                                        </div>
                                        <div className='w-40 hidden sm:block'></div>
                                    </div>
                                    <div className='flex w-fit h-[calc(100%-71px)] items-center overflow-hidden relative m-auto'>
                                        <div className="relative w-full h-full flex items-center justify-center">
                                            <LandMap
                                                theme={theme}
                                                metric={metricMap}
                                                chartData={countryData}
                                                setTooltipContent={setContent}
                                                setValueFunction={setSelectedCountry}
                                                selectedValue={selectedCountry}
                                                color={colors[brand] ? colors[brand] : colors['default']}
                                                style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} // Limit the size of LandMap
                                            />
                                            <Tooltip anchorSelect="#mapchart" className='font-heading text-xs'>{content}</Tooltip>
                                            <div className="absolute top-0 right-1 ml-4">
                                                {selectedCountry
                                                    ? <div className='flex items-center'>
                                                        <FunnelIcon className='w-3 h-3 text-zinc-400 mr-1' />
                                                        <span className="text-xs sm:text-xs font-heading text-zinc-400">{selectedCountry}</span>
                                                        <Button
                                                            color={theme === 'dark' ? 'dark' : 'white'}
                                                            onClick={() => setSelectedCountry(null)}
                                                            className="text-xs sm:text-xs"
                                                            plain
                                                        >
                                                            <XCircleIcon />
                                                        </Button>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id='orders' className='col-span-1 sm:col-span-4 xl:col-span-3 row-span-3 sm:row-span-2 xl:row-span-3 h-96 sm:h-full rounded-xl shadow bg-zinc-100 dark:bg-zinc-900 overflow-hidden'>
                                    <div className='flex h-12 sm:h-11 xl:h-11 w-full rounded-t-lg bg-white dark:bg-zinc-800 items-center justify-between space-between'>
                                        <div className='flex w-40'>
                                            {metricGraph === 'Orders'
                                                ? <ShoppingBagIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                                : metricGraph === 'Revenue'
                                                    ? <BanknotesIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                                    : <UserIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                            }
                                            <span className='my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>{metricGraph}</span>
                                            {loadingOrders ? <div className="my-auto ml-2 border-zinc-300 dark:border-zinc-700 h-3 w-3 animate-spin rounded-full border-2 border-t-zinc-700 dark:border-t-zinc-300" /> : null}
                                        </div>
                                        <div className='my-auto'>
                                            <Tabs tabs={tabs} defaultTab={defaultTab} setTabValue={switchMapGraphValue} />
                                        </div>
                                        <div className="flex w-fit justify-end">
                                            <div className="relative mr-2 sm:mr-4">
                                                <select
                                                    className="appearance-none text-right form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-xs sm:text-xs xl:text-xs text-zinc-700 dark:text-zinc-100 focus:border-transparent focus:ring-0 focus:outline-none"
                                                    defaultValue={filterDatepart}
                                                    value={filterDatepart}
                                                    onChange={e => setFilterDatepart(e.target.value)}
                                                >
                                                    <option value="year">Year</option>
                                                    <option value="month">Month</option>
                                                    <option value="week">Week</option>
                                                    <option value="day">Day</option>
                                                    <option value="hour">Hour</option>
                                                    <option value="weekday">Weekday</option>
                                                    <option value="yyyy-MM">Year month</option>
                                                    <option value="yyyy-MM-dd">Date</option>
                                                    <option value="yyyy-MM-dd-HH">Date hour</option>
                                                    <option value="relative">Relative date</option>
                                                    <option value="ou">Organization</option>
                                                </select>
                                                <ChevronDownIcon className='text-zinc-600 dark:text-zinc-200 white pointer-events-none absolute inset-y-0 right-1 w-4 h-full' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-3 pt-2 w-full h-[calc(100%-71px)] items-center align-middle relative'>
                                        <ChartComponent
                                            showAxisLineY={true}
                                            showAxisLineX={false}
                                            horizontal={false}
                                            theme={theme}
                                            chartData={[
                                                {
                                                    name: "Last period" + (similar ? '' : " (disabled)"),
                                                    data: similarTimelineData.values,
                                                    type: chart === 'bar' ? similarTimelineData.values[0] === null ? 'column' : 'area' : null
                                                },
                                                {
                                                    name: "Selected period",
                                                    data: timelineData.values,
                                                    type: chart === 'bar' ? 'column' : null
                                                }
                                            ]}
                                            chartCategories={timelineData.categories}
                                            selectedType={filterDatepart}
                                            setValueFunction={setSelectedBarDates}
                                            selectedValue={selectedBarDates}
                                            color={colors[brand] ? colors[brand] : colors['default']}
                                        />
                                        <div className="absolute top-0 right-1 z-10">
                                            <Button
                                                color={theme === 'dark' ? 'dark' : 'white'}
                                                onClick={() => setChart(chart === "bar" ? "line" : "bar")}
                                                className="text-xs sm:text-xs"
                                                plain
                                            >
                                                {chart === "bar" ? <PresentationChartBarIcon /> : <PresentationChartLineIcon />}
                                            </Button>
                                        </div>
                                        <div className="absolute top-0 right-10">
                                            {selectedBarDates
                                                ? <div className='flex items-center'>
                                                    <FunnelIcon className='w-3 h-3 text-zinc-400 mr-1' />
                                                    <span className="text-xs sm:text-xs font-heading text-zinc-400">{selectedBarDates[1] === selectedBarDates[2] ? selectedBarDates[1] : selectedBarDates[1] + " - " + selectedBarDates[2]}</span>
                                                    <Button
                                                        color={theme === 'dark' ? 'dark' : 'white'}
                                                        onClick={() => setSelectedBarDates(null)}
                                                        className="text-xs sm:text-xs"
                                                        plain
                                                    >
                                                        <XCircleIcon />
                                                    </Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </main >
                    {
                        isSmallScreen
                            ? <div className={`fixed w-full xl:w-[30%] h-full xl:h-[75%] bottom-0 right-0 xl:bottom-4 xl:right-4 z-10 shadow-xl ring-1 ring-zinc-400 ring-opacity-20 xl:rounded-xl ${showChat ? '' : 'hidden'}`
                            }>
                                <div className='hidden xl:flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                    <ChatBubbleOvalLeftEllipsisIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                    <span className='resize my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>AI Chat</span>
                                    <XMarkIcon onClick={() => setShowChat(showChat ? false : true)} className='text-zinc-800 dark:text-white mr-4 my-auto w-4 align ml-auto' />
                                </div>
                                <div className='w-full h-full xl:h-[calc(100%-44px)] items-center align-middle relative'>
                                    <Chat
                                        user={user.EmailAddress}
                                        id={chat}
                                        theme={theme}
                                        config={{ endpoint: url[brand] ? url[brand] : url['default'], token: token }}
                                        reset={resetChat}
                                        handleFilters={applyFilters}
                                    />
                                </div>
                            </div >
                            : <Draggable className={`${showChat ? '' : 'hidden'}`}>
                                <div className={`fixed w-full xl:w-[30%] h-full xl:h-[75%] bottom-0 right-0 xl:bottom-4 xl:right-4 z-10 shadow-xl ring-1 ring-zinc-400 ring-opacity-20 xl:rounded-xl ${showChat ? '' : 'hidden'}`}>
                                    <div className='hidden xl:flex h-12 sm:h-11 xl:h-11 rounded-t-lg bg-white dark:bg-zinc-800'>
                                        <ChatBubbleOvalLeftEllipsisIcon className='text-zinc-800 dark:text-white ml-5 my-auto w-4 align' />
                                        <span className='resize my-auto ml-3 text-sm sm:text-xs xl:text-xs text-zinc-800 dark:text-white xl:font-semibold  font-heading'>AI Chat</span>
                                        <XMarkIcon onClick={() => setShowChat(showChat ? false : true)} className='text-zinc-800 dark:text-white mr-4 my-auto w-4 align ml-auto' />
                                    </div>
                                    <div className='w-full h-full xl:h-[calc(100%-44px)] items-center align-middle relative'>
                                        <Chat
                                            user={user.EmailAddress}
                                            id={chat}
                                            theme={theme}
                                            config={{ endpoint: url[brand] ? url[brand] : url['default'], token: token }}
                                            reset={resetChat}
                                            handleFilters={applyFilters}
                                        />
                                    </div>
                                </div>
                            </Draggable>
                    }
                </div >
                : <Loading theme={theme} />}
        </>
    )
}


