import React, { Component } from "react";
import { ComposableMap, Geography, Geographies, ZoomableGroup } from "react-simple-maps";
import colors from "tailwindcss/colors";
import { scaleLinear } from "d3-scale";

const geoUrl = "/worldmap.json";
const countryCodes2 = await fetch('/countries2.json').then(r => r.json())
const countryCodes3 = await fetch('/countries3.json').then(r => r.json())

class LandMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: props.theme,
            metric: props.metric,
            chartData: props.chartData,
            position: { coordinates: [0, 0], zoom: 1 }
        };
        this.colorScale = scaleLinear()
            .domain([0, 1])
            .range([colors[this.props.color][900], colors[this.props.color][200]]);
    }

    componentDidMount() {
        this.setState({
            theme: this.props.theme,
            metric: this.props.metric,
            chartData: this.props.chartData,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.chartData !== this.props.chartData || prevProps.theme !== this.props.theme) {
            this.setState({
                theme: this.props.theme,
                metric: this.props.metric,
                chartData: this.props.chartData,
            });
            this.colorScale.range(this.props.theme === 'dark' ? [colors[this.props.color][900], colors[this.props.color][200]] : [colors[this.props.color][200], colors[this.props.color][900]]);
        }
    }

    updateColorScale() {
        this.colorScale.domain([
            Math.min(...this.state.chartData.map(item => item.result)),
            Math.max(...this.state.chartData.map(item => item.result))
        ]);
    }

    handleZoomIn = () => {
        this.setState(prevState => {
            if (prevState.position.zoom >= 4) return null;
            return {
                position: {
                    ...prevState.position,
                    zoom: prevState.position.zoom * 2
                }
            };
        });
    }

    handleZoomOut = () => {
        this.setState(prevState => {
            if (prevState.position.zoom <= 1) return null;
            return {
                position: {
                    ...prevState.position,
                    zoom: prevState.position.zoom / 2
                }
            };
        });
    }

    render() {
        this.updateColorScale();

        const linearScaler = this.colorScale;
        const numSegments = 5; // Number of segments in the legend

        const domain = linearScaler.domain();
        const segmentValues = [];
        for (let i = 0; i < numSegments; i++) {
            const value = domain[0] + (i / (numSegments - 1)) * (domain[1] - domain[0]);
            segmentValues.push(value);
        }

        return (
            <div className="w-full h-full relative" >
                <ComposableMap className="w-full h-full" >
                    <ZoomableGroup disablePanning zoom={this.state.position.zoom} center={this.state.position.coordinates}>
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const d = this.state.chartData.find((s) => countryCodes2[s.country] === geo.id);
                                    return (
                                        <Geography
                                            id="mapchart"
                                            key={geo.rsmKey}
                                            geography={geo}
                                            onMouseEnter={() => {
                                                if (d) {
                                                    this.props.setTooltipContent(this.state.metric + ' in ' + geo.properties.name + ': ' + (d['result'] % 1 === 0 ? d['result'] : d['result'].toFixed(2)));
                                                }
                                                else {
                                                    this.props.setTooltipContent(geo.properties.name);
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                this.props.setTooltipContent("");
                                            }}
                                            onClick={() => this.props.setValueFunction(countryCodes2[this.props.selectedValue] !== geo.id ? countryCodes3[geo.id] : null)}
                                            style={{
                                                default: {
                                                    fill: geo.id === countryCodes2[this.props.selectedValue]
                                                        ? this.state.theme === 'dark'
                                                            ? colors.zinc[100]
                                                            : colors.zinc[800]
                                                        : d
                                                            ? this.colorScale(d['result'])
                                                            : this.state.theme === 'dark'
                                                                ? colors.zinc[600]
                                                                : colors.zinc[300],
                                                    outline: "none"
                                                },
                                                hover: {
                                                    fill: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[600],
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    fill: colors.white,
                                                    outline: "none"
                                                }
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
                <div className={`absolute flex bottom-0 left-5 sm:left-2 space-x-1 font-heading ${this.state.theme === 'dark' ? 'text-zinc-300' : 'text-zinc-600'} text-xs`}>
                    {this.state.chartData.length > 0
                        ? segmentValues.map((value, index) => (
                            <div key={index} className="flex items-center flex-col">
                                <div className="w-3 h-3 rounded-lg mb-1" style={{ backgroundColor: linearScaler(value), opacity: '90%' }} />
                                <div>{value.toFixed(0)}</div>
                            </div>
                        ))
                        : null}
                </div>
                <div className={`absolute flex bottom-0 right-5 sm:right-2 space-x-1 ${this.state.theme === 'dark' ? 'text-zinc-300' : 'text-zinc-600'} text-xs`}>
                    <button onClick={this.handleZoomIn} className={`rounded-md p-1 ${this.state.theme === 'dark' ? 'bg-zinc-700' : 'bg-zinc-200'}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="3"
                        >
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </button>
                    <button onClick={this.handleZoomOut} className={`rounded-md p-1 ${this.state.theme === 'dark' ? 'bg-zinc-700' : 'bg-zinc-200'}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="3"
                        >
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </button>
                </div>
            </div>
        )
    }
}

export default LandMap;
