import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs({ tabs, defaultTab, setTabValue }) {
  const [metric, setMetric] = useState(tabs[defaultTab].name)

  function handleTabChange(data) {
    setTabValue(data);
    setMetric(data);
  }

  return (
    <div>
      <div className="relative md:hidden">
        <select
          id="tabs"
          name="tabs"
          className="appearance-none text-right form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-xs sm:text-xs 2xl:text-sm text-zinc-700 dark:text-zinc-100 focus:border-transparent focus:ring-0 focus:outline-none"
          defaultValue={metric.name}
          onChange={(e) => handleTabChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>{tab.name}</option>
          ))}
        </select>
        <ChevronDownIcon className='text-zinc-600 dark:text-zinc-200 pointer-events-none absolute inset-y-0 right-1 w-4 h-full' />
      </div>
      <div className="hidden md:block">
        <div className="flex space-x-2 sm:space-x-0 2xl:space-x-2 p-1 rounded-lg bg-zinc-100 dark:bg-zinc-900" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => handleTabChange(tab.name)}
              className={classNames(
                (tab.name === metric) ? 'bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100' : 'text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-200',
                'rounded-md px-3 sm:px-1 2xl:px-2 py-2 sm:py-1 2xl:py-1 text-xs sm:text-xs 2xl:text-[0.65rem] font-normal w-24 2xl:w-20 text-center'
              )}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
