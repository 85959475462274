import React, { useState, useRef, useEffect } from 'react';
import queryString from 'query-string';
import Notification from '../notification'

import { ReactComponent as SendIcon } from '../../assets/send.svg';
import ChatMessage from '../chat/ChatMessage.js';
import ChatOverlay from '../chat/ChatOverlay.js';
import Login from '../../pages/signin.js';
import Loading from '../../pages/loading'
import { MicrophoneIcon } from '@heroicons/react/24/outline';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export default function Chat({ user, id, theme, config, reset, handleFilters }) {
    const messagesContainer = useRef(null);
    const [chat, setChat] = useState(id);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [answering, setAnswering] = useState(false);
    const [isOverlayHidden, setIsOverlayHidden] = useState(false);
    const [loading, setLoading] = useState(true);

    const {
        transcript,
        listening,
    } = useSpeechRecognition();

    // Trigger to get the messages of a conversation
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const response = await fetch('https://ca-eva-databot-api-test.livelyfield-c1b731eb.westeurope.azurecontainerapps.io/api/v1/chatbot/GetMessages?user_id=' + user + '&chat_id=' + id, {
                        headers: {
                            'x-api-key': 'SIGa5tQhmF',
                            "Content-Type": "application/json",
                            'Accept': 'text/event-stream'
                        },
                        withCredentials: false,
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setChat(id);
                        setMessages(data.messages);
                    }
                }
                else {
                    setMessages([]);
                    setLoading(false);
                }
            } catch (error) {
                setMessages([]);
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [user, id]);

    useEffect(() => {
        // Scroll automatically down to bottom
        if (messagesContainer.current) {
            messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
        }
        // Hide suggestion messsages when chat is started
        if (messages.length === 0) {
            setIsOverlayHidden(false);
        }
        else {
            setIsOverlayHidden(true);
        }
    }, [messages]);

    useEffect(() => {
        // Reset chat when variable is triggered
        setChat(null);
        setMessages([]);
    }, [reset]);

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleSend = (event) => {
        event.preventDefault();
        console.log('Message received: ' + message)
        document.getElementById('user-message').value = '';
        setMessage('');
        handleUserMessage(message);
    };


    const handleUserMessage = async (message) => {
        setAnswering(true);
        // Set user message and blinking cursor for chatbot
        setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: "●", additional_context: {}, conversation: {} }]);

        // Request to get an ansewer
        try {
            const params = {
                chat_id: chat,
                user_id: user,
                stream: true,
                skip_user_info: true,
                skip_conversation_title: false,
            };
            // Filter out properties with null values
            const cleanedParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== null));
            const paramsCleaned = queryString.stringify(cleanedParams);

            const response = await fetch('https://ca-eva-databot-api-test.livelyfield-c1b731eb.westeurope.azurecontainerapps.io/api/v1/chatbot/GetAnswer?' + paramsCleaned, {
                method: "POST",
                headers: {
                    'x-api-key': 'SIGa5tQhmF',
                    'x-eva-endpoint': config.endpoint,
                    'x-eva-token': config.token,
                    "Content-Type": "application/json",
                    'Accept': 'text/event-stream'
                },
                body: JSON.stringify({ "message": message })
            });
            if (!response.ok) {
                Notification('error', 'Something went wrong!', 'Please try again or contact support.')
            }

            // Here we start prepping for the streaming response
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            const loopRunner = true;

            let answer = '';
            let additional_context = {};
            let conversation = {};
            while (loopRunner) {
                // Here we start reading the stream, until its done.
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                const decodedChunk = decoder.decode(value, { stream: true });
                const replacedChunk = decodedChunk.replace(/}{/g, '}|{')
                const chunks = replacedChunk.split('|')

                for (const chunk of chunks) {
                    // Define additional context
                    const chunkContext = JSON.parse(chunk)?.additional_context;
                    if (chunkContext !== undefined) {
                        additional_context = chunkContext;
                    };
                    // Define conversation
                    const chunkConversation = JSON.parse(chunk)?.conversation;
                    if (chunkConversation !== undefined) {
                        conversation = chunkConversation;
                    };
                    // Define answer
                    const chunkMessage = JSON.parse(chunk)?.message;
                    if (chunkMessage !== undefined) {
                        const words = chunkMessage.split(" ");
                        for (let i = 0; i < words.length; i++) {
                            if (i + 1 < words.length) {
                                answer += words[i] + " ";
                            }
                            else {
                                answer += words[i];
                            }
                            setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: answer.trim() + "▌", additional_context: additional_context, conversation: conversation }]);
                            await new Promise(resolve => setTimeout(resolve, 1));
                        }
                    }
                    // const chunkMessage = JSON.parse(chunk)?.message;
                    // if (chunkMessage !== undefined) {
                    //     // Iterate through each letter to get a typing effect
                    //     for (let i = 0; i < chunkMessage.length; i++) {
                    //         answer += chunkMessage[i];
                    //         setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: answer + "▌", additional_context: additional_context, conversation: conversation }]);
                    //     }
                    // }
                }
            }
            // At the end update the complete answer without blink
            setMessages([...messages, { role: "user", content: message }, { role: "assistant", content: answer, additional_context: additional_context, conversation: conversation }]);
            setAnswering(false);

            // Handle filtering
            if (additional_context?.filters) {
                handleFilters(additional_context?.filters)
            }

            //Update url when new chat
            if (chat === null) {
                console.log("Chat: " + conversation?.chat_id);
                setChat(conversation?.chat_id)
            }
        }
        catch (error) {
            console.error('Error getting answer:', error);
            setAnswering(false);
            Notification('error', 'An error occured', 'please try again or contact support.')
        }
    };

    const handleSuggestionChatText = (suggestion) => {
        setMessage(suggestion);
    };

    const handleMicrophone = () => {
        if (listening) {
            SpeechRecognition.stopListening();
        } else {
            SpeechRecognition.startListening();
        }
    };

    useEffect(() => {
        setMessage(transcript);
    }, [transcript]);

    return (
        loading ? <Loading /> :
            <>
                {user ?
                    <div className='flex flex-col h-full overflow-y-auto pt-2 bg-gradient-to-b from-75% from-zinc-100 dark:from-zinc-900 to-zinc-200 dark:to-zinc-800 rounded-b-lg'>
                        <div ref={messagesContainer} className="flex-1 w-full ms-0 overflow-auto">
                            <div className="sm:hidden p-2"></div>
                            {!isOverlayHidden &&
                                <div className="flex items-center justify-center h-full">
                                    <ChatOverlay setSuggestionChatText={handleSuggestionChatText} />
                                </div>
                            }
                            {messages.map((msg, index) => {
                                var last;
                                if (index + 1 === messages.length) {
                                    last = true;
                                }
                                else {
                                    last = false;
                                }
                                if ((msg.role === 'assistant' || msg.role === 'user') && msg.content !== null && msg?.additional_context?.show !== false) {
                                    return <ChatMessage key={index} chat={chat} id={msg.additional_context?.message_id} role={msg.role} content={msg.content} last={last} user={user} liked={msg.additional_context?.like} component={msg.additional_context?.component} chart={msg.additional_context?.chart} theme={theme} />;
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div className='sticky bottom-3 sm:bottom-1 bg-whites'>
                            <div className="w-full shrink-0 pt-2 flex justify-center" >
                                <div className="max-w-3xl sm:px-4 lg:px-6 px-4 w-full mx-auto">
                                    <form id='chat-input' onSubmit={handleSend}>
                                        <div className="flex items-end gap-4 w-full bg-white dark:bg-zinc-900 py-2 px-4 rounded-md border border-solid border-zinc-300 dark:border-zinc-700 cursor-text focus-within:border-zinc-700 dark:focus-within:border-zinc-300 focus-within:border text-black dark:text-white">
                                            <input id="user-message" name="query" rows="1" placeholder="Send a message" className="m-0 w-full bg-white dark:bg-zinc-900 max-h-[200px] resize-none border-0 outline-none text-sm" value={message} onChange={handleMessage}></input>
                                            <div className='flex m-auto'>
                                                <span className='flex cursor-pointer z-10' onClick={handleMicrophone}>
                                                    <MicrophoneIcon className="w-4 h-4 my-auto mr-1 text-zinc-600 dark:text-zinc-200" />
                                                </span>
                                                <span class={`animate-ping absolute mt-1  h-3 w-3 ml-0.5 rounded-full bg-red-400 opacity-75 ${listening ? '' : 'hidden'}`}></span>
                                                <button id='chat-input-button' type="submit" disabled={!message.trim() || answering} className={`outline-none ${!message.trim() ? 'cursor-not-allowed' : ''} shrink-0`}>
                                                    {answering
                                                        ? <div className="border-zinc-300 h-4 w-4 animate-spin rounded-full border-4 border-t-zinc-400" />
                                                        : <SendIcon className={`w-5 h-5 t ${!message.trim() ? 'text-zinc-400' : 'text-black dark:text-white'}`} />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="flex flex-col items-center gap-5 py-1 sm:py-2 px-2 text-zinc-500 dark:text-zinc-400 w-full">
                                        <span class="text-xs text-center">AI Chat can make mistakes. Check important info.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <>
                        <Login theme={theme} />
                    </>
                }
            </>
    );
}

