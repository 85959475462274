import { CursorArrowRippleIcon, ComputerDesktopIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline'

import Header from '../components/header';

import StoreImage from '../assets/newblack_store.jpg'
import In2Intel from '../assets/in2intel.png'
import NewBlack from '../assets/newblack.png'

const incentives = [
    {
        name: 'One-Click Deployment',
        imageSrc: CursorArrowRippleIcon,
        description: "Deploying your module within your EVA system is effortless—just a single click initiates the process seamlessly.",
    },
    {
        name: 'Dynamically',
        imageSrc: Square3Stack3DIcon,
        description: "Our modules are engineered to seamlessly interact with your other systems, ensuring smooth data flow and maximizing operational efficiency.",
    },
    {
        name: 'Continuous Monitoring and Maintenance',
        imageSrc: ComputerDesktopIcon,
        description: "We proactively monitor your components, ensuring they remain operational and up-to-date, providing you with peace of mind and uninterrupted performance.",
    },
]

export default function About() {
    return (
        <div className='flex sm:min-h-screen w-screen bg-gradient-to-tr from-black from-80% sm:from-60% to-gray-700 to-100% sm:to-90%'>
            <Header />
            <div className="m-auto max-w-7xl py-28 sm:px-2 sm:py-32 lg:px-4">
                <div className="mx-auto max-w-2xl px-6 lg:max-w-none">
                    <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
                        <div>
                            <h2 className="text-4xl font-medium sm:font-bold tracking-tight text-gray-100 font-heading">
                                Unlock the Power of Analytics and AI with EVA-Driven Components
                            </h2>
                            <p className="mt-4 text-gray-400">
                                Harnessing the capabilities of our EVA system, we offer a suite of standardized analytics and AI components tailored to your needs. From robust data warehousing solutions to dynamic product recommendation engines or even chatbots, our offerings are designed to elevate your business strategies across various fronts, including personalization, marketing efficacy, and in-depth insights for optimizing KPIs.
                            </p>
                            <p className="mt-4 text-gray-400">
                                Continuously evolving with the latest technological advancements, our standardized components seamlessly integrate with your EVA system, ready for deployment with just a single click. Experience the efficiency and effectiveness of cutting-edge analytics and AI solutions tailored to your business objectives.
                            </p>
                        </div>
                        <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
                            <img
                                src={StoreImage}
                                alt=""
                                className="object-cover object-center"
                            />
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                        {incentives.map((incentive) => (
                            <div key={incentive.name} className="sm:flex lg:block">
                                <div className="sm:flex-shrink-0">
                                    <incentive.imageSrc className="h-12 w-12 text-gray-100 hover:animate-pulse" alt="" />
                                </div>
                                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                                    <h3 className="text-sm font-medium sm:font-semibold text-gray-100 font-heading">{incentive.name}</h3>
                                    <p className="mt-2 text-sm text-gray-400">{incentive.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-32 sm:mt-32 mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-center text-lg font-medium sm:font-extrabold leading-8 text-gray-200 font-heading">
                            We do this together with our most innovative partners
                        </h2>
                        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-6">
                            <a className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 sm:col-start-2 lg:col-start-3" href="https://newblack.io">
                                <img
                                    src={NewBlack}
                                    alt=""
                                    width={158}
                                    height={48}
                                />
                            </a>
                            <a className="col-span-2 object-contain lg:col-span-1 sm:col-start-4 lg:col-start-4" href="https://in2intel.com">
                                <img
                                    className="max-h-12 object-contain grayscale hover:grayscale-0"
                                    src={In2Intel}
                                    alt=""
                                    width={158}
                                    height={48}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
