import ContextualCommerce from '../assets/contextualcommerce.pdf'
import Header from '../components/header';

export default function Home() {
    return (
        <div className='relative min-h-screen overflow-y-hidden'>
            <div className="bg-black h-screen">
                <Header />
                <div className="flex h-full isolate px-6 pt-14 lg:px-8">
                    <div
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] animate-gradient-x bg-gradient-to-r from-indigo-400 via-sky-400 to-gray-200 opacity-40 sm:opacity-40 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="m-auto max-w-2xl">
                        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white/50 ring-1 ring-white/30 hover:ring-white/20">
                                Explore the Future of Contextual Commerce.{' '}
                                <a href={ContextualCommerce} target='_blank' rel="noreferrer" className="font-semibold text-white">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    Read more <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="text-4xl font-medium sm:font-extrabold tracking-tight text-white sm:text-6xl font-heading">
                                Elevate Your Business with EVA-Powered Analytics & AI Solutions
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Experience the next level of business optimization with our suite of EVA-driven analytics and AI components. Tailored solutions designed to elevate your online ventures by providing personalized insights and maximizing efficiency.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href="/marketplace"
                                    className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                                >
                                    View our marketplace
                                </a>
                                <a href="/about" className="text-sm font-semibold leading-6 text-white">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 animate-gradient-x bg-gradient-to-r from-indigo-400 via-sky-400 to-gray-200 opacity-40 sm:opacity-40 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem] background-animate"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
