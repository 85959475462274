const { CosmosClient } = require("@azure/cosmos");

const connections = {
  default: {
    endpoint: "https://eventexportdb.documents.azure.com",
    key: "5uVtKcCWpmCQ2sZs43qUHcBJXCWWGpdyhCUvunpjh1wH5241GRiRN60Fw5RVxCGO8k9YyphNxrcjACDbb4kViQ==",
    db: "evadata",
    containers: {
      order: "Order",
      customer: "Customer"
    }
  },
  guc: {
    endpoint: "https://eventexportdb.documents.azure.com",
    key: "5uVtKcCWpmCQ2sZs43qUHcBJXCWWGpdyhCUvunpjh1wH5241GRiRN60Fw5RVxCGO8k9YyphNxrcjACDbb4kViQ==",
    db: "evadata",
    containers: {
      order: "Order",
      customer: "Customer"
    }
  },
  ajax: {
    endpoint: "https://ajaxcosmosdb.documents.azure.com",
    key: "7EELFt0axgxaq4BqBnAZlU6CGG6jb6DIZzN1Jzww6LDZ7yyM9Msoc7TNlNcN7QgnDplkMvHWt3PvdA1x2Buclg==",
    db: "newblack",
    containers: {
      order: "orderData_prod",
      customer: "customerData_prod"
    }
  },
};

function getBrandFromUrl(url) {
  const parts = url.split('/');
  var result = null;
  if (parts.includes('report')) {
    result = parts[parts.length - 2];
  }
  else {
    result = parts[parts.length - 1];
  }
  return result
}
const brand = getBrandFromUrl(window.location.href);
const { endpoint, key, db, containers } = connections[brand] ? connections[brand] : connections['default'];
const client = new CosmosClient({
  endpoint,
  key,
  connectionPolicy: {
    enableEndpointDiscovery: false
  }
});
const database = client.database(db);

module.exports = {
  database,
  containers
};
