import React, { Component } from "react";
import Chart from "react-apexcharts";
import colors from "tailwindcss/colors";

class ComboChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAxisLineY: false,
      showAxisLineX: true,
      horizontal: false,
      theme: 'dark',
      chartData: [
        {
          name: "Period before",
          data: [null],
          type: 'column'
        },
        {
          name: 'Current',
          data: [0],
          type: 'column'
        }
      ],
      chartCategories: [0]
    };
  }

  componentDidMount() {
    this.setState({
      showAxisLineY: this.props.showAxisLineY,
      showAxisLineX: this.props.showAxisLineX,
      horizontal: this.props.horizontal,
      theme: this.props.theme,
      chartData: this.props.chartData,
      chartCategories: this.props.chartCategories,
      chartOptions: this.props.chartOptions
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme || prevProps.chartData !== this.props.chartData || prevProps.chartCategories !== this.props.chartCategories) {
      this.setState({
        showAxisLineY: this.props.showAxisLineY,
        showAxisLineX: this.props.showAxisLineX,
        horizontal: this.props.horizontal,
        theme: this.props.theme,
        chartData: this.props.chartData,
        chartCategories: this.props.chartCategories,
      });
    }
  }

  render() {
    return (
      <Chart
        options={{
          chart: {
            id: "combo-chart",
            stacked: true,
            toolbar: {
              show: false,
            },
            // events: {
            //   dataPointSelection: (event, chartContext, config) => {
            //     this.props.selectedType
            //       ? this.props.setValueFunction([
            //         this.props.selectedType,
            //         this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null,
            //         this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null
            //       ])
            //       : this.props.setValueFunction(this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null);
            //   }
            // }
            events: {
              dataPointSelection: (event, chartContext, config) => {
                this.props.setValueFunction([
                  this.props.selectedType,
                  this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null,
                  this.state.chartCategories[config.dataPointIndex] !== this.props.selectedValue ? this.state.chartCategories[config.dataPointIndex] : null
                ])
              },
              zoomed: (chartContext, { xaxis, yaxis }) => {
                this.props.setValueFunction([
                  this.props.selectedType,
                  this.state.chartCategories[xaxis.min],
                  this.state.chartCategories[xaxis.max - 1]
                ])
              },
              beforeZoom: function (ctx) {
                // we need to clear the range as we only need it on the iniital load.
                ctx.w.config.xaxis.range = undefined
              }
            },
            zoom: {
              enabled: true,
              type: 'x',
              autoScaleYaxis: false,
              zoomedArea: {
                fill: {
                  color: this.state.theme === 'dark' ? colors.zinc[100] : colors.zinc[900],
                  opacity: 0.4
                },
                stroke: {
                  color: this.state.theme === 'dark' ? colors.zinc[600] : colors.zinc[300],
                  opacity: 0.4,
                  width: 1
                }
              }
            }
          },
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: 'SK-Modernist',
            },
            onDatasetHover: {
              style: {
                fontSize: "12px",
                fontFamily: 'SK-Modernist',
              },
            },
            theme: this.state.theme,
          },
          xaxis: {
            // min: this.props.selectedValue ? this.props.chartCategories.indexOf(this.props.selectedValue[1] + 1) : undefined,
            // max: this.props.selectedValue ? this.props.chartCategories.indexOf(this.props.selectedValue[2] + 1) : undefined,
            categories: this.state.chartCategories,
            show: false,
            labels: {
              show: true,
              hideOverlappingLabels: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "11px",
                fontWeight: "500",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            min: 0,
            color: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
            labels: {
              show: true,
              style: {
                colors: this.state.theme === 'dark' ? colors.zinc[400] : colors.zinc[500],
                fontSize: "11px",
                fontWeight: "500",
              },
              formatter: function (val) {
                return parseFloat(val) > 0
                  ? val % 1 === 0
                    ? val
                    : val.toFixed(2)
                  : val;
              }
            },
          },
          stroke: {
            curve: 'smooth',
            width: [2, 0]
          },
          markers: {
            size: 3,
            colors: this.state.theme === 'dark' ? [colors.zinc[900], colors[this.props.color][900]] : [colors.zinc[100], colors[this.props.color][100]],
            strokeColor: this.state.theme === 'dark' ? [colors.zinc[400], colors[this.props.color][300]] : colors.zinc[600],
            strokeWidth: 1
          },
          grid: {
            borderColor: this.state.theme === 'dark' ? colors.zinc[700] : colors.zinc[300],
            show: true,
            yaxis: {
              lines: {
                show: this.state.showAxisLineY,
                opacity: 0.5,
              },
            },
            row: {
              opacity: 0.5,
            },
            xaxis: {
              lines: {
                show: this.state.showAxisLineX,
                opacity: 0.5,
              },
            },
          },
          colors: this.state.theme === 'dark' ? [colors.zinc[600], colors.zinc[300]] : [colors.zinc[400], colors.zinc[600]],
          fill: {
            type: 'solid',
            opacity: [0.2, 1]
          },
          legend: {
            show: true,
            fontSize: "11px",
            fontFamily: 'SK-Modernist',
            floating: false,
            position: 'top',
            horizontalAlign: 'left',
            labels: {
              colors: this.state.theme === 'dark' ? [colors.zinc[300], colors.zinc[300]] : [colors.zinc[800], colors.zinc[800]]
            },
            markers: {
              width: 9,
              height: 9,
              fillColors: this.state.theme === 'dark' ? [colors.zinc[600], colors.zinc[300]] : [colors.zinc[400], colors.zinc[600]]
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: this.state.horizontal,
              borderRadius: this.state.chartCategories !== undefined && this.state.chartCategories.length >= 8 ? 3 : 5,
              columnWidth: "80%",
              stacked: true,
              borderRadiusOnAllStackedSeries: true
            },
          },
        }}
        series={this.state.chartData}
        type='line'
        width='100%'
        height='100%'
      />

    );
  }
}


// // ComboChart.hideSeries('Period before')
// const chart = Chart.getChartByID('combo-chart')
// chart.hideSeries("Period before")
export default ComboChart;
