import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
    atomDark
} from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { ClipboardIcon, HandThumbDownIcon, HandThumbUpIcon, ChartBarIcon, PresentationChartLineIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { HandThumbDownIcon as HandThumbDownIconSolid, HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/20/solid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Notification from '../notification'

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../components/dropdown'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/dialog'
import { Button } from '../../components/button';

import logo from '../../assets/logo_small.png'
import KPI from './charts/KPI';
import BarChart from './charts/BarChart';
import AreaChart from "./charts/AreaChart";
import { createDashboard, getEditableDashboards, patchDashboardChart } from "../../api/dashboards";
import { Field, Label } from "../fieldset";
import { Select } from "../select";
import { Input } from "../input";
import { Description } from '../fieldset'
import { Switch, SwitchField } from '../switch'

export default function ChatMessage({ chat, id, role, content, last, user, liked, component, chart, theme }) {
    const [feedback, setFeedback] = useState(liked);
    const [viewQuery, setViewQuery] = useState(false);
    const [addDashboard, setAddDashboard] = useState(false);
    const [dashboards, setDashboards] = useState([]);
    let [checked, setChecked] = useState(false)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    async function handleFeedback(like) {
        try {
            await axios.post('https://ca-eva-databot-api-test.livelyfield-c1b731eb.westeurope.azurecontainerapps.io/api/v1/chatbot/GiveFeedback', {}, {
                headers: {
                    'x-api-key': 'SIGa5tQhmF',
                    'accept': 'application/json'
                },
                params: {
                    chat_id: chat,
                    user_id: user,
                    message_id: id,
                    like: like
                },
                withCredentials: false,
            });

            Notification('success', 'Saved your feedback!', 'Thank you for your feedback.')

            setFeedback(like);
            console.log("Given feedback: " + feedback);
        } catch (error) {
            console.log('Error giving feedback:', error);
            Notification('error', 'An error occured!', 'Please try again or contact support.')
        }
    }

    function handleCopy(message) {
        navigator.clipboard.writeText(message);
        Notification('info', 'Copied to clipboard!', 'Selected text is saved to your clipboard.')
    }

    function renderChart(chart) {
        var chartData = [];
        var chartCategories = [];
        var groupedData = [];
        var metric = '';

        switch (true) {
            case chart?.type?.includes('metric'):
                return <KPI title={chart?.title} value={chart?.data[0][chart?.x]} background={'my-6 bg-gradient-to-br from-zinc-500 from-10% to-zinc-400 to-80%'} />;
            case chart?.type?.includes('bar'):
                metric = chart?.y;
                groupedData = chart.data.reduce((acc, curr) => {
                    const groupName = curr[chart.hue];
                    if (!acc[groupName]) {
                        acc[groupName] = [];
                    }
                    acc[groupName].push(curr[chart?.y]);
                    return acc;
                }, {});
                chartData = Object.entries(groupedData).map(([name, data]) => ({
                    name,
                    data
                }));
                chartCategories = Array.from(new Set(chart?.data.map(item => item[chart?.x])));

                return <div className="mt-6 mb-2 w-full h-96 rounded-xl shadow dark:shadow-zinc-700 bg-white dark:bg-zinc-950 ring-1 ring-zinc-400 dark:ring-zinc-800 ring-opacity-5">
                    <div className='flex h-12 sm:h-12 2xl:h-12 rounded-t-lg bg-zinc-50 dark:bg-zinc-900 border-b border-zinc-200 dark:border-zinc-700'>
                        <ChartBarIcon className='text-zinc-800 dark:text-zinc-200 ml-4 my-auto w-4 align' />
                        <span className='my-auto text-center ml-3 font-light text-xs text-zinc-800 dark:text-zinc-200 hidden sm:block overflow-x-hidden'>{chart?.title}</span>
                    </div>
                    <div className='px-3 w-full h-[calc(100%-61px)] items-center align-middle relative'>
                        <BarChart showAxisLineY={true} showAxisLineX={false} theme={theme} horizontal={false} chartData={chartData} chartCategories={chartCategories} titleAxisY={metric} />
                    </div>
                </div>;
            case chart?.type?.includes('line'):
                metric = chart?.y;
                groupedData = chart.data.reduce((acc, curr) => {
                    const groupName = curr[chart.hue];
                    if (!acc[groupName]) {
                        acc[groupName] = [];
                    }
                    acc[groupName].push(curr[chart?.y]);
                    return acc;
                }, {});
                chartData = Object.entries(groupedData).map(([name, data]) => ({
                    name,
                    data
                }));
                chartCategories = Array.from(new Set(chart?.data.map(item => item[chart?.x])));

                return <div className="mt-6 mb-2 w-full h-96 rounded-xl shadow dark:shadow-zinc-700 bg-white dark:bg-zinc-950 ring-1 ring-zinc-400 dark:ring-zinc-800 ring-opacity-5">
                    <div className='flex h-12 sm:h-12 2xl:h-12 rounded-t-lg bg-zinc-50 dark:bg-zinc-900 border-b border-zinc-200 dark:border-zinc-700'>
                        <PresentationChartLineIcon className='text-zinc-800 dark:text-zinc-200 ml-4 my-auto w-4 align' />
                        <span className='my-auto text-center ml-3 font-light text-xs text-zinc-800 dark:text-zinc-200 hidden sm:block overflow-x-hidden'>{chart?.title}</span>
                    </div>
                    <div className='px-3 w-full h-[calc(100%-61px)] items-center align-middle relative'>
                        <AreaChart showAxisLineY={true} showAxisLineX={false} theme={theme} horizontal={false} chartData={chartData} chartCategories={chartCategories} titleAxisY={metric} />
                    </div>
                </div>;
            default:
                return null;
        }
    }

    useEffect(() => {
        const fetchDashboards = async () => {
            try {
                var dashboardData = await getEditableDashboards(user);
                setDashboards(dashboardData);
            } catch (error) {
                console.log(error);
            }
        };

        if (addDashboard) { fetchDashboards() }
    }, [addDashboard, user]);

    const handleAddChart = async () => {
        try {
            var dashboardId = null;
            if (checked) {
                const title = document.getElementById('dashboard-new').value;
                var response = await createDashboard(user, title);
                dashboardId = response.id;
            }
            else {
                dashboardId = document.getElementById('dashboard-existing').value;
            }
            patchDashboardChart(dashboardId, user, 'add', chart)
            Notification('success', 'Chart added.', ' is successfully added to your dahsboard .')
        } catch (error) {
            Notification('error', 'Something went wrong.', 'An error occured when adding the chart your dashboard, please try again or contact support.')
            console.log(error);
        }
    };

    return (
        <>

            <Dialog open={viewQuery} onClose={setViewQuery}>
                <DialogTitle>Query viewer</DialogTitle>
                <DialogDescription>
                    {chart?.title}
                </DialogDescription>
                <DialogBody>
                    <div className="flex-col w-full mt-2 gap-1 md:gap-3 text-zinc-800 dark:text-zinc-200 text-sm">
                        <ReactMarkdown
                            className={`space-y-5 break-words text-zinc-700 dark:text-zinc-300'}`}
                            children={"```sql\n" + chart?.query}
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <div className="bg-zinc-800 rounded-md my-5">
                                            <div className="flex text-zinc-200 rounded-t-md font-heading text-xs pt-1.5 pb-0.5 px-3 justify-between">
                                                <div className="text-start mr-auto">
                                                    {match[1]}
                                                </div>
                                            </div>
                                            <SyntaxHighlighter
                                                children={String(children).replace(/\n$/, '')}
                                                language={match[1]}
                                                style={atomDark}
                                                PreTag="div"
                                                className="mockup-code bg-black text-xs rounded-b-md scrollbar-thin scrollbar-track-base-content/5 scrollbar-thumb-base-content/40 scrollbar-track-rounded-md scrollbar-thumb-rounded"
                                                showLineNumbers={true}
                                                useInlineStyles={true}

                                                {...props}
                                            />
                                        </div>
                                    ) : (
                                        <code className="text-xs  inline-flex text-left items-center space-x-4 bg-neutral-800 text-blue-300 rounded-md px-1.5 py-0.5" {...props}>
                                            {children}
                                        </code>
                                    )
                                },
                            }}
                        />
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setViewQuery(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={addDashboard} onClose={setAddDashboard}>
                <DialogTitle>Add chart</DialogTitle>
                <DialogDescription>
                    Choose a dashboard or create a new one where you want to publish the {chart?.type} chart '{chart?.title}'.
                </DialogDescription>
                <DialogBody>
                    <SwitchField className="mb-5">
                        <Label>{checked ? 'To a new dashboard' : 'To an existing dahsboard'}</Label>
                        <Description>{checked ? 'This will create a new dashboard and adds the chart to it.' : 'This will add the chart to the selected dashboard.'}</Description>
                        <Switch name="new" checked={checked} onChange={setChecked} />
                    </SwitchField>
                    {checked
                        ? <Field>
                            <Label>Title</Label>
                            <Input id="dashboard-new" name="dashboard" defaultValue={"Dashboard of " + user} />
                        </Field>
                        : <Field>
                        <Label>Dashboard</Label>
                        <Select id="dashboard-existing" name="dashboard">
                            {dashboards.map((dashboard, index) => (
                                <option value={dashboard.id}>{dashboard.title}</option>
                            ))}
                        </Select>
                    </Field>
                    }
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setAddDashboard(false)}>
                        Close
                    </Button>
                    <Button onClick={() => {handleAddChart(); setAddDashboard(false)}}>
                        Add chart
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                className={"p-2 flex gap-4 overflow-x-auto" + classNames(
                    role === "assistant" ? "border-y border-solid border-zinc-100 " : ""
                    , last ? 'pb-8' : null
                )}
            >
                <div className="flex gap-3 sm:max-w-3xl sm:px-6 lg:px-8 px-4 w-full mx-auto">
                    <div className="flex-shrink-0 flex flex-col relative items-end">
                        <div className="pt-0.5">
                            <div className={`${role === "assistant" ? 'bg-white' : 'bg-white'} gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full text-black`}>
                                <div className="relative flex">
                                    {role === "assistant" ?
                                        <img alt="User" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" className="rounded-sm " src={logo} /> :
                                        user.substring(0, 1).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full pr-0 sm:pr-12 flex-col">
                        <div className="font-bold text-zinc-900 dark:text-zinc-100 text-sm">
                            {role === "assistant" ? "New Black" : "You"}
                        </div>
                        <div className="flex-col w-full mt-2 gap-1 md:gap-3 text-zinc-800 dark:text-zinc-200 text-sm">
                            {content === '●'
                                ? <div className="relative">
                                    <div className="absolute mt-2 w-3 h-3 rounded-full bg-zinc-600 dark:bg-zinc-400 animate-ping overflow-x-visible"></div>
                                    <div className="absolute mt-2 w-3 h-3 rounded-full bg-zinc-600 dark:bg-zinc-400"></div>
                                </div>
                                : null}
                            <ReactMarkdown
                                className={`space-y-5 break-words ${content === '●' ? 'text-zinc-100 dark:text-zinc-900' : 'text-zinc-700 dark:text-zinc-300'}`}
                                children={content}
                                components={{
                                    ul: ({ node, ...props }) => (<ul className="block list-disc list-outside" {...props} />),
                                    ol: ({ node, ...props }) => (<ul className="block list-decimal list-outside" {...props} />),
                                    li: ({ node, ...props }) => (<li className="marker:text-zinc-400 marker:font-heading marker:text-sm pl-1" {...props} />),
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <div className="bg-zinc-800 rounded-md my-5">
                                                <div className="flex text-zinc-200 rounded-t-md font-heading text-xs pt-1.5 pb-0.5 px-3 justify-between">
                                                    <div className="text-start mr-auto">
                                                        {match[1]}
                                                    </div>
                                                </div>
                                                <SyntaxHighlighter
                                                    children={String(children).replace(/\n$/, '')}
                                                    language={match[1]}
                                                    style={atomDark}
                                                    PreTag="div"
                                                    className="mockup-code bg-black text-xs rounded-b-md scrollbar-thin scrollbar-track-base-content/5 scrollbar-thumb-base-content/40 scrollbar-track-rounded-md scrollbar-thumb-rounded"
                                                    showLineNumbers={true}
                                                    useInlineStyles={true}

                                                    {...props}
                                                />
                                            </div>
                                        ) : (
                                            <code className="text-xs  inline-flex text-left items-center space-x-4 bg-neutral-800 text-blue-300 rounded-md px-1.5 py-0.5" {...props}>
                                                {children}
                                            </code>
                                        )
                                    },
                                }}
                            />

                            {/* Button */}
                            <div className="flex">
                                {(content.match(/(https?:\/\/[^\s]+)/g) || []).map((url, index) => (
                                    <a
                                        key={index}
                                        className="my-4 text-white dark:text-black bg-black dark:bg-white hover:bg-zinc-700 dark:hover:bg-zinc-300 focus:ring-4 focus:outline-none focus:ring-zinc-400 font-medium rounded-lg text-sm font-heading px-4 py-3 text-center"
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Click here
                                    </a>
                                ))}
                            </div>
                            <div className="flex">
                                {component?.type === 'button' ? <a className="my-4 text-white dark:text-black bg-black dark:bg-white hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-zinc-400 font-medium rounded-lg text-sm font-heading px-4 py-3 text-center" href={component?.href}>{component?.text}</a> : null}
                            </div>

                            {/* Charts */}
                            <div className="flex relative">
                                {renderChart(chart)}
                            </div>

                            {/* Message functions */}
                            {role === "assistant" ?
                                <div className="group mt-2 flex justify-start gap-3 empty:hidden">
                                    <div className="text-zinc-500 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1">
                                        <span>
                                            <button title="Copy" onClick={() => handleCopy(content)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-zinc-950 dark:text-zinc-500 dark:hover:text-zinc-200 disabled:dark:hover:text-zinc-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                                <ClipboardIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                            </button>
                                        </span>
                                        <span>
                                            <button title="Like" onClick={() => handleFeedback(true)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-zinc-950 dark:text-zinc-500 dark:hover:text-zinc-200 disabled:dark:hover:text-zinc-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                                {feedback === true
                                                    ? <HandThumbUpIconSolid className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                                    : <HandThumbUpIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />}
                                            </button>
                                        </span>
                                        <span>
                                            <button title="Dislike" onClick={() => handleFeedback(false)} className="flex items-center gap-1.5 rounded-md p-1 text-xs hover:text-zinc-950 dark:text-zinc-500 dark:hover:text-zinc-200 disabled:dark:hover:text-zinc-400 md:group-hover:visible md:group-[.final-completion]:visible">
                                                {feedback === false
                                                    ? <HandThumbDownIconSolid className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />
                                                    : <HandThumbDownIcon className={` ${last ? null : 'invisible'} group-hover:visible w-4 stroke-2`} />}
                                            </button>
                                        </span>
                                        {chart
                                            ? <div className="absolute -bottom-1.5 left-16">
                                                <Dropdown>
                                                    <DropdownButton plain aria-label="More options" className={` ${last ? null : 'invisible'} group-hover:visible hover:text-zinc-950 dark:text-zinc-500 dark:hover:text-zinc-200 disabled:dark:hover:text-zinc-400 md:group-hover:visible md:group-[.final-completion]:visible`}>
                                                        <Cog8ToothIcon className="w-4 stroke-2 hover:text-zinc-950 dark:text-zinc-400 dark:hover:text-zinc-200" />
                                                    </DropdownButton>
                                                    <DropdownMenu anchor="bottom end">
                                                        <DropdownItem className="text-sm" onClick={() => setViewQuery(true)}>View query</DropdownItem>
                                                        <DropdownItem className="text-sm" onClick={() => setAddDashboard(true)}>Add to dashboard</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                : <div className="mt-2 flex h-5" />
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
