import { database, containers } from './configs/DashboardConfig';
import { database as orderdatabase, containers as ordercontainers } from './configs/CosmosConfig';

export const getDashboards = async (user) => {
    try {
        const container = database.container(containers.config);
        var query = {
            query: `
            SELECT 
                c.id AS id
                ,c.title AS title
                ,c.user AS creator
                ,c.access['` + user + `'].rights AS access
            FROM 
                c 
            WHERE 
                (c.user = '` + user + `' OR IS_DEFINED(c.access['` + user + `']))
                AND c.deleted = false
            `
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();
        return resources;
    } catch (error) {
        console.error('Error fetching dashboards:', error);
        throw error;
    }
};

export const getEditableDashboards = async (user) => {
    try {
        const container = database.container(containers.config);
        var query = {
            query: `
            SELECT 
                c.id AS id
                ,c.title AS title
                ,c.user AS creator
            FROM 
                c 
            WHERE 
                c.deleted = false
                AND (c.user = '` + user + `' OR c.access['` + user + `'].rights IN ('admin', 'edit'))
            `
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();
        return resources;
    } catch (error) {
        console.error('Error fetching dashboards:', error);
        throw error;
    }
};

export const patchDeleteDashboard = async (id, creator) => {
    try {
        const container = database.container(containers.config);
        const operations = [
            { op: 'replace', path: '/deleted', value: true },
        ];

        const { resource: updated } = await container
            .item(
                id,
                creator
            )
            .patch(operations);
        return updated;
    } catch (error) {
        console.error('Error deleting dashboard:', error);
        throw error;
    }
};

export const patchDashboardAccessRights = async (id, creator, user, operation, rights) => {
    try {
        if (operation === 'addOrReplace') {
            try {
                // Try to replace the property
                const container = database.container(containers.config);
                const operations = [{ op: 'replace', path: '/access/' + user + '/rights', value: rights }];
                const { resource: updated } = await container
                    .item(id, creator)
                    .patch(operations);
                return updated;
            } catch (error) {
                try {
                    // Add property when replacing was not successfull
                    const container = database.container(containers.config);
                    const operations = [
                        { op: 'add', path: '/access/' + user, value: {} },
                        { op: 'add', path: '/access/' + user + '/rights', value: rights },
                    ];
                    const { resource: updated } = await container
                        .item(id, creator)
                        .patch(operations);
                    return updated;
                } catch (error) {
                    console.error('Error updating dashboard access rights:', error);
                    throw error;
                }
            }
        }
        else if (operation === 'delete') {
            try {
                // Try to replace the property
                const container = database.container(containers.config);
                const operations = [
                    { op: 'remove', path: '/access/' + user + '/rights' },
                    { op: 'remove', path: '/access/' + user }
                ];
                const { resource: updated } = await container
                    .item(id, creator)
                    .patch(operations);
                return updated;
            } catch (error) {
                console.error('Error updating dashboard access rights:', error);
                throw error;
            }
        }
        else {
            return "Wrong operation"
        }
    } catch (error) {
        console.error('Error updating dashboard access rights:', error);
        throw error;
    }
};

export const getDashboard = async (id, user) => {
    try {
        const container = database.container(containers.config);
        var query = {
            query: `
            SELECT 
                TOP 1 *
            FROM 
                c 
            WHERE 
                (c.user = '` + user + `' OR IS_DEFINED(c.access['` + user + `']))
                AND c.deleted = false
                AND c.id = '` + id + `'
            `
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();
        return resources[0];
    } catch (error) {
        console.error('Error fetching dashboards:', error);
        throw error;
    }
};

export const createDashboard = async (creator, title) => {
    try {
        const container = database.container(containers.config);
        var item = {
            'id': crypto.randomUUID(),
            'user': creator,
            'title': title,
            'dashboard': {},
            'access': {},
            'deleted': false
        };
        item.access[creator] = {
            'rights': [
                'admin'
            ]
        }
        var response = await container.items.upsert(item);
        return response.item
    } catch (error) {
        console.error('Error creating dashboard:', error);
        throw error;
    }
};

export const changeDashboardTitle = async (id, creator, title) => {
    try {
        // Try to replace the property
        const container = database.container(containers.config);
        const operations = [{ op: 'replace', path: '/title', value: title }];
        const { resource: updated } = await container
            .item(id, creator)
            .patch(operations);
        return updated;
    } catch (error) {
        console.error('Error updating dashboard title:', error);
        throw error;
    }
}

export const patchDashboardChart = async (id, creator, operation, chartItem) => {
    try {
        if (operation === 'replace') {
            try {
                // Try to replace the property
                const container = database.container(containers.config);
                const operations = [{ op: 'replace', path: '/dashboard/' + chartItem?.id, value: chartItem }];
                const { resource: updated } = await container
                    .item(id, creator)
                    .patch(operations);
                return updated;
            } catch (error) {
                console.error('Error updating dashboard chart:', error);
                throw error;
            }
        }
        else if (operation === 'add') {
            try {
                // Add property when replacing was not successfull
                const container = database.container(containers.config);
                const chart_id = crypto.randomUUID();
                // Add id to object and remove the data
                var duplicateChart = Object.assign({}, chartItem);
                duplicateChart['id'] = chart_id;
                delete duplicateChart['data'];
                const operations = [{ op: 'add', path: '/dashboard/' + chart_id, value: duplicateChart }];
                const { resource: updated } = await container
                    .item(id, creator)
                    .patch(operations);
                return updated;
            } catch (error) {
                console.error('Error adding dashboard chart:', error);
                throw error;
            }
        }
        else if (operation === 'delete') {
            try {
                // Try to replace the property
                const container = database.container(containers.config);
                const operations = [{ op: 'remove', path: '/dashboard/' + chartItem?.id }];
                const { resource: updated } = await container
                    .item(id, creator)
                    .patch(operations);
                return updated;
            } catch (error) {
                console.error('Error deleting dashboard chart:', error);
                throw error;
            }
        }
        else {
            return "Wrong operation"
        }
    } catch (error) {
        console.error('Error updating dashboard access rights:', error);
        throw error;
    }
};

export const queryData = async (input) => {
    try {
        const container = orderdatabase.container(ordercontainers.order);
        var query = {
            query: String(input)
        };
        const { resources } = await container.items
            .query(query)
            .fetchAll();
        return resources;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const patchChartValue = async (operation, id, creator, chart, key, input) => {
    try {
        // Try to replace the property
        const container = database.container(containers.config);
        const operations = [
            {
                op: operation,
                path: (operation === 'remove' ? '/dashboard/' + chart : '/dashboard/' + chart + "/" + key),
                value: input
            }
        ];
        const { resource: updated } = await container
            .item(id, creator)
            .patch(operations);
        return updated;
    } catch (error) {
        console.error('Error updating dashboard value:', error);
        throw error;
    }
}

export const patchDashboardLayout = async (id, creator, layout) => {
    try {
        // Try to replace the property
        const container = database.container(containers.config);
        const operations = [{ op: 'replace', path: '/layout/', value: layout }];
        const { resource: updated } = await container
            .item(id, creator)
            .patch(operations);
        return updated;
    } catch (error) {
        try {
            // Add property when replacing was not successfull
            const container = database.container(containers.config);
            const operations = [{ op: 'add', path: '/layout', value: layout }];
            const { resource: updated } = await container
                .item(id, creator)
                .patch(operations);
            return updated;
        } catch (error) {
            console.error('Error updating dashboard layout:', error);
            throw error;
        }
    }
};