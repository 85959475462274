import { database, containers } from './configs/CosmosConfig';

export const getCustomers = async (OrganizationUnits, dates, filters) => {
    try {
        const dateFrom = dates.startDate;
        const dateTo = dates.endDate;

        var source = 'c'
        var filter = 'c.IsPaid = true AND IS_DEFINED(c.Customer.ID)'

        if (OrganizationUnits.length > 0) {
            filter += ' AND c.SoldFromOrganizationUnitID IN (' + OrganizationUnits + ')'
        }
        if (dateFrom && dateTo) {
            filter += ' AND DateTimeToTimestamp(c.PaymentDate) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.PaymentDate) < ' + dateTo
        }

        if(filters?.CountryID) {
            filter += " AND  c.ShippingAddress.CountryID = '" + filters.CountryID + "'"
        }

        if (filters?.PaymentMethod) {
            source += " JOIN p in c.Payments"
            filter += " AND  p.Description = '" + filters.PaymentMethod + "'"
        }

        if (filters?.Product) {
            source += " JOIN l in c.Lines"
            filter += " AND  l.Product.PrimitiveName LIKE '" + filters.Product + "%'"
        }

        if (filters?.BarDates && filters?.BarDates[0] === 'ou') {
            filter += " AND c.SoldFromOrganizationUnitData.Name = '" + filters?.BarDates[1] + "'"
        }
        if (filters?.BarDates && filters?.BarDates[0] !== 'ou') {
            if (String(filters?.BarDates[1]).includes('-')) {
                filter += " AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") >= '" + filters.BarDates[1] + "' AND LEFT(c.PaymentDate, " + String(filters.BarDates[0]).length + ") <= '" + filters.BarDates[2] + "'"
            }
            else {
                filter += " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) >= " + filters.BarDates[1] + " AND DateTimePart('" + filters.BarDates[0] + "', c.PaymentDate) <= " + filters.BarDates[2]
            }
        }

        // Query total customers
        var query = {
            query: `
                SELECT
                    DISTINCT c.Customer.ID AS ID
                FROM
                    ` + source + `   
                WHERE
                    ` + filter + `   
            `
        };
        const container = database.container(containers.order);
        const { resources: customers } = await container.items
            .query(query)
            .fetchAll();

        // Query new customers
        const customerIds = customers.map(item => item.ID);
        var customerFilter = 'c.IsDeleted = false';
        if (customerIds.length > 0) {
            customerFilter += ' AND c.ID IN (' + customerIds + ')'
        }
        if (dateFrom && dateTo) {
            customerFilter += ' AND DateTimeToTimestamp(c.CreationTime) >= ' + dateFrom + ' AND DateTimeToTimestamp(c.CreationTime) < ' + dateTo
        }

        var customerQuery = {
            query: `
                SELECT
                    c.id
                FROM
                    ` + source + `   
                WHERE
                    ` + customerFilter + `   
            `
        };
        const customer = database.container(containers.customer);
        var { resources: newCustomers } = await customer.items
            .query(customerQuery)
            .fetchAll();
        const newCustomerIds = newCustomers.map(item => parseInt(item.id));

        const returningCustomerIds = customerIds.filter(item => !newCustomerIds.includes(item));

        const result = {
            new: newCustomerIds.length,
            returning: customerIds.length - newCustomerIds.length,
            total: customerIds.length,
            new_perc: newCustomerIds.length / customerIds.length * 100,
            returning_perc: (customerIds.length - newCustomerIds.length) / customerIds.length * 100,
            new_customers: newCustomerIds,
            returning_customers: returningCustomerIds
        }

        return result;
    } catch (error) {
        console.error('Error fetching customers:', error);
        throw error;
    }
};