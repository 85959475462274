const { CosmosClient } = require("@azure/cosmos");

const connections = {
  default: {
    endpoint: "https://cosmos-eva-westeu-test.documents.azure.com",
    key: "IhAl9wifegK655U5T5iyxJmlRsli4iMilyxGeBDsE1EW1olGjP1ack9MYQjpoQ333XkH6FNxq8QYACDbtbIrtQ==",
    db: "dashboard",
    containers: {
      config: "config",
    }
  },
};

const { endpoint, key, db, containers } = connections['default'];

const client = new CosmosClient({
  endpoint,
  key,
  connectionPolicy: {
    enableEndpointDiscovery: false
  }
});
const database = client.database(db);

module.exports = {
  database,
  containers
};
