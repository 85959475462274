import { useState } from 'react'

import ProductDetail from './product'
import Header from '../components/header';

import Lakehouse from '../assets/marketplace/lakehouse.jpg'
import Warehouse from '../assets/marketplace/warehouse.jpeg'
import EventMiddleware from '../assets/marketplace/eventmiddleware.png'
import Chatbot from '../assets/marketplace/commercechatbot.png'
import ProductReccomendation from '../assets/marketplace/productrec.jpeg'
import Basket from '../assets/marketplace/basket.jpg'
import Dashboard from '../assets/marketplace/dashboard.png'
import CustomerSegmentation from '../assets/marketplace/customersegmentation.png'
import ConversationOptimization from '../assets/marketplace/conversionrate.jpg'
import ChurnRate from '../assets/marketplace/churnrate.webp'

const products = [
    {
        id: 1,
        name: 'Event Middleware',
        category: 'Integrations',
        href: '#',
        price: 'Free',
        imageSrc: EventMiddleware,
        description: "Introducing Event Middleware, your streamlined solution for seamless integration between EVA and external systems. This module efficiently captures and stores every EVA event in Cosmos DB and Service Bus, providing a reliable source of real-time data. With its intuitive interface, Event Middleware enables quick and precise subscription to specific topics, empowering consumers to effortlessly integrate EVA data into their external systems. Accelerate your integration processes and unlock the full potential of EVA with Event Middleware, your gateway to precision and efficiency."
    },
    {
        id: 2,
        name: 'Databricks Lakehouse',
        category: 'Warehouses',
        href: '#',
        price: 'Free',
        imageSrc: Lakehouse,
        description: "Unified. Open. Scalable. The Databricks Data Intelligence Platform is revolutionizing data management with its innovative lakehouse architecture. By seamlessly merging the strengths of data lakes and data warehouses, our platform empowers you to cut costs and accelerate your data and AI projects. Driven by open-source technologies and adhering to open standards, our lakehouse architecture simplifies your data infrastructure, breaking down silos that hinder efficiency in data and AI endeavors. With built-in pipelines for extracting all EVA data into the lakehouse, Databricks offers a unified solution for all your data needs. Experience the power of unified data management with Databricks, where innovation meets simplicity."
    },
    {
        id: 3,
        name: 'Datawarehouse SQL',
        category: 'Warehouses',
        href: '#',
        price: 'Free',
        imageSrc: Warehouse,
        description: "The cornerstone of your reporting infrastructure, meticulously crafted to empower data-driven decision-making. Seamlessly integrated with EVA, our warehouse serves as a centralized repository, optimized for storing and analyzing vast amounts of data for insightful reporting. Our data pipelines are engineered to efficiently extract all relevant data from EVA, ensuring seamless integration and synchronization with the warehouse. With meticulous attention to optimization, our warehouse is designed to handle large datasets and complex queries, delivering lightning-fast performance for your reporting needs. Unlock the power of your data with our SQL Data Warehouse. From generating comprehensive reports to uncovering valuable insights, our solution provides the foundation for informed decision-making and strategic planning. Experience the difference of a truly data-driven approach with our SQL Data Warehouse at the core."
    },
    {
        id: 4,
        name: 'Abandoned Basket Mail',
        category: 'Marketing',
        href: '#',
        price: 'Free',
        imageSrc: Basket,
        description: "Seamlessly integrated with EVA, this intelligent system is designed to recover potentially lost sales by automatically detecting and sending personalized emails to customers who have abandoned their shopping carts. Never miss an opportunity to re-engage with your customers and recover lost revenue. Our Abandoned Basket Mailer module identifies open baskets within EVA, analyzes customer behavior, and crafts targeted emails to encourage completion of the purchase. With customizable templates and smart scheduling options, you can deliver timely reminders and special offers to entice customers back to complete their purchases. Say goodbye to lost sales and hello to increased conversions with our Abandoned Basket Mailer module."
    },
    {
        id: 5,
        name: 'Product Recommendation',
        category: 'Analytics',
        href: '#',
        price: 'Free',
        imageSrc: ProductReccomendation,
        description: "Integrated seamlessly with EVA and capable of incorporating additional variables from external systems, this cutting-edge engine redefines the shopping experience by delivering tailored product recommendations based on each customer's unique preferences and behavior. Powered by state-of-the-art algorithms, our recommendation engine analyzes vast amounts of data to accurately predict and suggest products that align with your customers' interests, browsing history, and past purchases. Whether it's fashion, electronics, or home goods, our engine ensures that each recommendation resonates with your customers, leading to increased engagement, satisfaction, and conversions. With the ability to dynamically adapt to evolving customer preferences and external variables, our Product Recommendation Engine offers unparalleled flexibility and precision in delivering personalized shopping experiences. Elevate your online store to new heights and delight your customers with relevant recommendations that keep them coming back for more."
    },
    {
        id: 6,
        name: 'Commerce Chatbot',
        category: 'AI',
        href: '#',
        price: 'Free',
        imageSrc: Chatbot,
        description: "Introducing our innovative Shopping Assistant Chatbot – your personalized shopping concierge designed to enhance your online shopping experience! Seamlessly integrated with our EVA system, this intelligent bot revolutionizes the way you shop by providing tailored recommendations, handling the entire order process, and offering expert advice. Experience the convenience of conversational commerce as our chatbot guides you through product selection, assists with placing orders, and keeps you informed about the latest deals and promotions. With access to real-time data from EVA, our bot ensures personalized recommendations based on your preferences and past purchases, making shopping a breeze. Say hello to a smarter, more efficient way to shop. Let our Shopping Assistant Chatbot be your virtual shopping companion, bringing convenience and personalized service to your fingertips. Say goodbye to endless browsing and hello to a seamless shopping experience."
    },
    {
        id: 7,
        name: 'Real-time Dashboard',
        category: 'Analytics',
        href: '#',
        price: 'Free',
        imageSrc: Dashboard,
        description: "Your dynamic window into the heart of your business operations, powered by comprehensive EVA data. Designed to provide actionable insights into sales performance and operational metrics, our dashboard offers a real-time view of your business landscape, empowering you to make informed decisions swiftly and confidently. With live updates and intuitive visualizations, our dashboard transforms raw EVA data into actionable intelligence, allowing you to monitor sales trends, track key performance indicators, and identify areas for optimization at a glance. From sales revenue and customer acquisition to inventory management and marketing effectiveness, our dashboard provides a holistic view of your business metrics, enabling you to stay ahead of the competition and drive growth. Experience the power of real-time insights with our Real-Time Dashboard. Elevate your decision-making process, streamline operations, and maximize profitability with actionable intelligence at your fingertips. Unlock the potential of your EVA data and take your business to new heights with our cutting-edge dashboard solution."
    },
    {
        id: 8,
        name: 'Customer Segmentation',
        category: 'AI',
        href: '#',
        price: 'Free',
        imageSrc: CustomerSegmentation,
        description: "Introducing our Customer Segmentation Engine – the key to unlocking personalized experiences across your email, website, and app platforms. Powered by sophisticated algorithms and leveraging comprehensive customer data from EVA, our segmentation engine dynamically categorizes your audience into distinct segments, enabling you to deliver targeted and relevant content tailored to each customer's preferences and behaviors. With our segmentation engine, you can create personalized email campaigns that resonate with specific customer segments, drive engagement on your website by delivering customized recommendations and promotions, and enhance user experience on your app by presenting content and features tailored to individual preferences. Say goodbye to generic messaging and hello to personalized experiences that drive customer loyalty and satisfaction. With our Customer Segmentation Engine, you can unlock the full potential of your customer data and deliver tailored experiences that keep your audience coming back for more."  
    },
    {
        id: 9,
        name: 'Conversion Optimization',
        category: 'Analytics',
        href: '#',
        price: 'Free',
        imageSrc: ConversationOptimization,
        description: "We understand the critical importance of conversion optimization in driving business success. That's why we're proud to offer a comprehensive module powered by EVA data, dedicated to this exact purpose. Our conversion optimization module is meticulously designed to fine-tune every aspect of your conversion funnel, leveraging advanced techniques and data-driven insights from EVA to maximize your conversion rates. With this module in place, you can rest assured that every element of your online presence – from landing pages to checkout processes – is optimized for peak performance based on real-time customer behavior and preferences. By harnessing the power of EVA data through our conversion optimization module, you can unlock the full potential of your digital assets and achieve unparalleled results."
        },
    {
        id: 10,
        name: 'Churn Rate Optimization',
        category: 'Analytics',
        href: '#',
        price: 'Free',
        imageSrc: ChurnRate,
        description: "We recognize the significance of churn rate optimization in maintaining long-term customer relationships and ensuring business sustainability. That's why we're excited to introduce our specialized module dedicated to churn rate optimization, powered by EVA data. Our churn rate optimization module is intricately crafted to analyze customer behavior and engagement patterns, utilizing insights derived from EVA data to identify potential churn risks and implement proactive strategies to mitigate them. By leveraging predictive analytics and machine learning algorithms, we can anticipate churn signals early on and take targeted actions to retain valuable customers. With our churn rate optimization module, you can proactively address churn risks, reduce customer attrition, and foster lasting relationships with your audience."
    },
]

export default function MarketPlace() {
    const [viewProduct, setViewProduct] = useState(false)
    const [product, setProduct] = useState(products[0])

    const onHandleViewProduct = (e) => {
        try {
            e.preventDefault();
            var productIndex = e.target.closest('button').id;
            setProduct(products[productIndex]);
            setViewProduct(true);
        }
        catch (error) {
            console.log(error);
            setViewProduct(false);
        }
        console.log(viewProduct);
    }

    return (
        <div className='min-h-screen bg-black'>
            {viewProduct
                ? <ProductDetail product={product} onHandleClose={onHandleViewProduct} />
                : null}
            <Header />
            <div className="mx-auto max-w-2xl px-6 py-24 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                    <h2 className="text-3xl font-medium sm:font-bold text-gray-100 font-heading">Marketplace</h2>
                    <a href="/about" className="whitespace-nowrap text-sm font-medium text-gray-100 hover:text-gray-300">
                        About
                        <span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>
                <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                    {products.map((product, index) => (
                        <div key={product.id} className="group relative">
                            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-900">
                                <img src={product.imageSrc} alt="" className="object-cover object-center min-h-44 opacity-80 group-hover:opacity-100" />
                                <div className="absolute bottom-16 left-0 right-0 flex items-end p-4 opacity-0 group-hover:opacity-100" aria-hidden="true">
                                    <div className="w-full rounded-md bg-white bg-opacity-75 px-4 py-2 text-center text-sm sm:font-medium text-gray-900 backdrop-blur backdrop-filter">
                                        View Product
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex items-center justify-between space-x-8 text-base font-semibold text-gray-100 font-heading">
                                <h3>
                                    <button id={index} key={product.id} onClick={onHandleViewProduct} >
                                        <span aria-hidden="true" className="absolute inset-0" />
                                        {product.name}
                                    </button>
                                </h3>
                                <p>{product.price}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-400">{product.category}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
