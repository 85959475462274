import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';

export default function Notification (type, title, description) {
    toast(
        <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white dark:bg-zinc-800">
            <div className="px-3 py-2">
                <div className="flex items-start">
                    <div className="flex-shrink-0">
                        {type === 'success'
                            ? <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            : type === 'error'
                                ? <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                : <InformationCircleIcon className="h-5 w-5 text-sky-400" aria-hidden="true" />
                        }
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="msg-title text-xs font-medium text-zinc-900 dark:text-zinc-100">{title}</p>
                        <p className="msg-description mt-1 text-xs text-zinc-500 dark:text-zinc-400">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};